import { ActionIcon, Group } from "@mantine/core";
import { formList, useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { FaTimesCircle } from "react-icons/fa";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { patchAuthVisitor } from "src/common/api";
import { AuthVisitor, House } from "src/common/api/types";
import { useAuthVisitorsQuery, useHouseQuery } from "src/common/hooks";
import { authVisitorUpdateSchema } from "src/common/schemas";
import { formatPhoneNumber, httpErrorMessage } from "src/common/utils";
import {
  Button,
  ChipsInputGroup,
  Drawer,
  PhoneInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  Tooltip,
  Typography,
} from "src/components";

export const EditAuthVisitorDrawer = ({
  setOpenDrawer,
  selectedUser,
}: {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
  selectedUser: AuthVisitor | undefined | null;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: housesData, refetch: housesRefetch } = useHouseQuery();

  const { refetch: authVisitorRefetch } = useAuthVisitorsQuery();

  const authForm = useForm({
    schema: zodResolver(authVisitorUpdateSchema),
    initialValues: {
      fname: selectedUser?.fname || "",
      lname: selectedUser?.lname || "",
      lname2: selectedUser?.lname2 || "",
      phone: formatPhoneNumber(selectedUser?.phone || ""),
      details: selectedUser?.details || "",
      type: selectedUser?.visitorType,
      authHouses: formList(
        selectedUser?.houses.map((house) => ({
          id: house.id,
          key: nanoid(),
        })) ?? [{ id: "", key: nanoid() }],
      ),
    },
  });

  const { mutate: submitPatchAuthVisitor, isLoading: patchAuthVisitorLoading } =
    useMutation(patchAuthVisitor, {
      onSuccess: () => {
        authForm.reset();
        handleDrawerClose();
        showNotification({
          title: "Success",
          message: "Authorized visitor created successfully",
          color: "green",
        });
        authVisitorRefetch();
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    });

  const handleDrawerClose = () => {
    const param = searchParams.get("id");

    if (param) {
      // delete each query param
      searchParams.delete("id");

      // update state after
      setSearchParams(searchParams);
    }

    authForm.reset();
    // setHouseMembers([]);
    setOpenDrawer(false);
  };

  const housesFields = authForm.values.authHouses?.map((item, index) => (
    <Group
      key={item.key}
      mt="xs"
      className={classNames({ "bg-hover": !(index % 2 === 0) })}
    >
      <SelectInput
        label="House Number"
        required
        key={`${item.key}-select`}
        // label="House Number"
        options={
          !housesData
            ? []
            : housesData?.houses?.map((house: House) => {
                return { value: house.id, label: house.number };
              })
        }
        onDropdownOpen={() => housesRefetch()}
        /*eslint-disable*/
        // @ts-ignore
        {...authForm.getListInputProps("authHouses", index, "id", {
          type: "input",
        })}
        /*eslint-enable*/
        value={item.id}
      />
      <Tooltip
        key={`${item.key}-tooltip`}
        label="Disconnect House"
        className="bg-error"
      >
        <ActionIcon
          key={`${item.key}-action`}
          color="red"
          variant="hover"
          onClick={() => authForm.removeListItem("authHouses", index)}
        >
          <FaTimesCircle />
        </ActionIcon>
      </Tooltip>
    </Group>
  ));

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={selectedUser !== null && searchParams.get("id") !== null}
      onFocus={() => setOpenDrawer(true)}
      onClose={handleDrawerClose}
      position="right"
      size="30%"
      withCloseButton
    >
      <Drawer.Header title="Edit Auth Visitor" />

      <form
        id="authForm"
        noValidate
        className="flex flex-col gap-y-2"
        onSubmit={authForm.onSubmit((data) => {
          console.log("SUBMIT", data);
          const body = {
            ...data,
            visitorType: data.type,
            id: selectedUser?.id,
            phone: data.phone === "None" ? undefined : data.phone,
          };
          submitPatchAuthVisitor(body);
        })}
      >
        <TextInput
          required
          form="authForm"
          label="First Name"
          {...authForm.getInputProps("fname")}
        />
        <TextInput
          required
          form="authForm"
          label="Last Name"
          {...authForm.getInputProps("lname")}
        />
        <TextInput
          form="authForm"
          label="Second Last Name"
          {...authForm.getInputProps("lname2")}
        />

        <PhoneInput {...authForm.getInputProps("phone")} />

        <TextAreaInput
          label="Details"
          placeholder="Additional Details"
          minRows={4}
          {...authForm.getInputProps("details")}
        />
        <div className="flex flex-wrap gap-5">
          <ChipsInputGroup
            required
            centerChips
            label="Select the type of visitor"
            onChange={(value) => {
              authForm.setFieldValue("type", value);
            }}
            value={authForm.values.type}
            chips={[
              // prettier-ignore
              { value: "VISITOR", children: "Visitor" },
              // prettier-ignore
              { value: "SERVICE", children: "Service"},
              // prettier-ignore
              { value: "CONTRACTOR", children: "Contractor" },
              // prettier-ignore
              { value: "FAMILY", children: "Family" },
            ]}
          />
          {authForm.errors.type && (
            <Typography type="regular" state="error">
              {authForm.errors.type}
            </Typography>
          )}
        </div>
        <div className="mt-5 flex justify-center">
          <Tooltip label="Adds existing house for this record">
            <Button
              text="Add House"
              variant="outlined"
              onClick={() =>
                authForm.addListItem("authHouses", {
                  id: "",
                  key: nanoid(),
                })
              }
            />
          </Tooltip>
        </div>
        <div></div>
        {housesFields}
        <div className="mt-5 flex justify-center">
          <Button
            loading={patchAuthVisitorLoading}
            text="Submit"
            type="submit"
            form="authForm"
            className="grow"
          />
        </div>

        {process.env.NODE_ENV === "development" && (
          <details className="olan">
            <pre>{JSON.stringify(selectedUser, null, 2)}</pre>
          </details>
        )}
      </form>
    </Drawer>
  );
};
