import { Tabs } from "@mantine/core";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { TabProps } from "./definitions";

export const Tab = ({
  variant = "outline",
  tabPadding = "lg",
  orientation = "horizontal",
  tabs,
  rootStyle,
  bodyStyle,
}: TabProps) => {
  return (
    <Tabs
      classNames={{
        tabsListWrapper: "border-none",
        tabControl: `rounded-t-lg px-6 ${classNames({
          "rounded-none bg-white": variant === "default",
        })}`,
        tabLabel: "font-semibold",
        tabActive: "!text-primary !border-2 !border-primary",
        root: rootStyle,
        body: bodyStyle,
      }}
      tabPadding={tabPadding}
      variant={variant}
      orientation={orientation}
    >
      {tabs.map((tab) => (
        <Tabs.Tab key={nanoid()} icon={tab.icon} label={tab.title}>
          {tab.content}
        </Tabs.Tab>
      ))}
    </Tabs>
  );
};
