import classNames from "classnames";
import { AccessKeyScheduleInputProps } from "./definitions";

export const AccessKeyScheduleInput = ({
  onChange,
  onClick,
  checked,
  name = "accessKeySchedule",
  value,
  defaultValue,
  label,
  htmlFor,
  icon,
  disabled,
}: AccessKeyScheduleInputProps) => {
  return (
    <div
      className={`relative flex aspect-square w-3/12 flex-col items-center justify-center rounded-3xl p-3 shadow-lg ${classNames(
        { "bg-primary text-white": checked },
        { "bg-white text-[#837A7A]": !checked },
        { "bg-gray-300 ": disabled },
      )}`}
    >
      <input
        className={`absolute z-40 h-full w-full opacity-0 ${classNames({
          "cursor-not-allowed": disabled,
          "cursor-pointer": !disabled,
        })}`}
        type="radio"
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <span
        className={`flex flex-col items-center justify-center gap-3 ${classNames(
          {
            "cursor-not-allowed": disabled,
          },
        )}`}
      >
        {icon}
        <label htmlFor={htmlFor}>{label}</label>
      </span>
    </div>
  );
};
