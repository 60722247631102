import { ActionIcon, DefaultMantineColor } from "@mantine/core";
import { Tooltip } from "src/components";

interface Props {
  icon?: React.ReactElement;
  iconColor?: DefaultMantineColor;
  className?: string;
  tooltipText: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}
/** Action Icon with optional tooltip integrated. */
export const ActionIconTooltip = ({
  icon,
  iconColor,
  className,
  tooltipText,
  onClick,
  disabled,
  loading,
}: Props) => (
  <Tooltip label={tooltipText} className={className}>
    <ActionIcon
      loading={loading}
      color={iconColor}
      variant="hover"
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </ActionIcon>
  </Tooltip>
);
