import { LoadingOverlay, Pagination } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useUserFetch } from "src/common/api/hooks";
import { User } from "src/common/api/types";
import { HttpCode } from "src/common/enums";
import { RefreshButton } from "src/common/features";
import { useUsersQuery } from "src/common/hooks";
import { useExpandStore } from "src/common/store";
import { formatPhoneNumber } from "src/common/utils";
import {
  Accordion,
  Button,
  Card,
  CardsContainer,
  ColorSwatch,
  Loader,
  Tab as TabContainer,
  Table,
  TextInput,
  Typography,
} from "src/components";
import { CreateUserDrawer, EditUserDrawer } from "./features/drawers";

export const Users = () => {
  const [openAddUserDrawer, setOpenAddUserDrawer] = useState(false);
  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);

  const [searchParams] = useSearchParams();
  const idFromParam = searchParams.get("id");

  const { isFetching, refetch } = useUsersQuery();
  const [{ data: selectedUser, loading: selectedUserLoading }] = useUserFetch({
    uid: idFromParam,
  });

  // TODO restructure so the filters and pagination are outside of the "UsersTable" component
  return (
    <CardsContainer className="pl-20">
      <div className="h-[92%]">
        <CreateUserDrawer
          openDrawer={openAddUserDrawer}
          setOpenDrawer={setOpenAddUserDrawer}
        />

        {/* // UGLY the overlay and Edit implementation are not ideal */}
        {selectedUserLoading && openEditUserDrawer === false && (
          <LoadingOverlay visible loader={<Loader size={"lg"} />} />
        )}

        {/* // UGLY the overlay and Edit implementation are not ideal */}
        {selectedUser && !selectedUserLoading && (
          <EditUserDrawer
            selectedUser={selectedUser.users[0]}
            openEditDrawer={openEditUserDrawer}
            setOpenEditDrawer={setOpenEditUserDrawer}
          />
        )}
        <div className="flex flex-row justify-between gap-3">
          <RefreshButton isRefreshing={isFetching} refetchMethod={refetch} />
          <Button
            text="Create User"
            icon={<FaUserPlus />}
            iconPosition="left"
            onClick={() => setOpenAddUserDrawer(true)}
          />
        </div>

        <Card className="h-full w-full overflow-y-auto">
          <UsersTable openEditDrawer={() => setOpenEditUserDrawer(true)} />
        </Card>
      </div>
    </CardsContainer>
  );
};

// const UserTypeCountPill = ({ type }: { type: string }) => {
//   /** The visitor logs data */
//   const [userManagementTypeData, setUserManagementTypeData] = useState<Log[]>();

//   const fetchUserManagementData = async () => {
//     await fetch("logsData.json", {
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     })
//       .then((response) => {
//         // console.log(response);
//         return response.json();
//       })
//       .then((json) => {
//         // console.log(json);
//         setUserManagementTypeData(json);
//       });
//   };

//   useEffect(() => {
//     fetchUserManagementData();
//   }, []);

//   return (
//     <span
//       className={`${classNames(
//         { "bg-warning": type === "service" },
//         { "bg-error": type === "visitor" },
//         { "bg-primary": type === "contractor" },
//       )} flex items-center gap-3 rounded-full px-3 py-1 text-white`}
//     >
//       {`${type.charAt(0).toUpperCase() + type.slice(1)} in Cluster`}{" "}
//       <span className="rounded-full bg-white px-2 text-sm text-black">
//         {!userManagementTypeData ? (
//           <Loader size={"xs"} variant={"oval"} />
//         ) : (
//           userManagementTypeData.filter(
//             (visitorLog) => visitorLog.type === type.toUpperCase(),
//           ).length
//         )}
//       </span>
//     </span>
//   );
// };

// eslint-disable-next-line
const UsersTable = ({ openEditDrawer }: { openEditDrawer?: () => void }) => {
  const [expanded, setExpanded] = useExpandStore();
  const [residentIsMainContact] = useState(false);
  const [residentIsActive] = useState(false);
  const [residentName, setResidentName] = useState("");
  const [residentSurnames] = useState("");
  const [residentAuthLvl] = useState("");
  // debounce states
  const [debouncedResidentIsMainContact] = useDebouncedValue(
    residentIsMainContact,
    500,
  );
  const [debouncedResidentIsActive] = useDebouncedValue(residentIsActive, 500);
  const [debouncedResidentName] = useDebouncedValue(residentName, 500);
  const [debouncedResidentSurnames] = useDebouncedValue(residentSurnames, 500);
  const [debouncedResidentAuthLvl] = useDebouncedValue(residentAuthLvl, 500);
  // pagination logic thanks to https://ilikekillnerds.com/2020/09/how-to-paginate-an-array-in-javascript/
  const [activePage, setPage] = useState(1);
  // const perPage = 7;
  const [perPage] = useState(7);

  const {
    data: residentsData,
    isLoading: residentsLoading,
    error: residentsError,
  } = useUsersQuery();

  // TODO this would be beneficial to reuse across pages/views
  useEffect(() => {
    if (residentsError) {
      showNotification({
        title: `Error ${residentsError?.response?.status}`,
        message:
          residentsError?.response?.status === HttpCode.UNAUTHORIZED
            ? "Not authorized, please contact support."
            : "An error ocurred",
        color: "red",
        disallowClose: true,
        autoClose: 7000,
      });
    }
  }, [residentsError]);

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      {/* Filters */}
      <div className="flex flex-wrap gap-x-3 gap-y-1">
        <TextInput
          label="Full Name"
          placeholder="Search by full name"
          size="xs"
          onChange={(e) => setResidentName(e.target.value)}
          value={residentName}
        />
        {/* <TextInput
          label="Surnames"
          placeholder="Search by surnames"
          size="xs"
          onChange={(e) => setResidentSurnames(e.target.value)}
          value={residentSurnames}
        /> */}
        {/* //FIX select input not setting state to "" if deleting selection */}

        {/* // NOTE removed until better implementation 2022-06-29T15:14:39.000-04:00*/}
        {/* <SelectInput
          label="Priority"
          size="xs"
          placeholder="Select level"
          options={["1st", "2nd"]}
          onChange={(e) => setResidentAuthLvl(e)}
          onSearchChange={(e) => setResidentAuthLvl(e)}
          value={residentAuthLvl}
        /> */}
      </div>

      {/* Accordions */}
      <div className="mt-5 h-[80%] overflow-y-auto">
        {residentsLoading || residentsError ? (
          <div className="flex h-full items-center justify-center">
            <Loader size={"lg"} />
          </div>
        ) : (
          <>
            <Accordion
              onChange={() => setExpanded(true)}
              items={
                // FIXME it complains about `residentsData` possibly undefined,
                // must find definitive solution 2022-06-29T22:40:28.000-04:00
                // @ts-ignore
                residentsData.users
                  .filter((user: User) => {
                    // REVIEW its really not, refactor.
                    // i don't know if this is the best implementation of the search, if anything is better than this feel free to change it

                    if (
                      !debouncedResidentIsMainContact &&
                      !debouncedResidentIsActive &&
                      !debouncedResidentName &&
                      !debouncedResidentSurnames &&
                      !debouncedResidentAuthLvl
                    ) {
                      return user;
                    }

                    if (debouncedResidentIsMainContact) {
                      return user.isMainContact;
                    }

                    if (debouncedResidentIsActive) {
                      return user.active;
                    }

                    if (debouncedResidentName) {
                      const mainContactFullName = `${user?.fname} ${user?.lname} ${user?.lname2}`;

                      return mainContactFullName.includes(
                        debouncedResidentName,
                      );
                      // return user?.fname?.includes(debouncedResidentName);
                    }
                    if (debouncedResidentSurnames) {
                      // prettier-ignore
                      return `${user?.lname} ${user?.lname2}`.includes(
                        debouncedResidentSurnames
                      );
                    }

                    if (debouncedResidentAuthLvl === "1st") {
                      return user.isMainContact;
                    }

                    if (debouncedResidentAuthLvl !== "1st") {
                      return !user.isMainContact;
                    }

                    return user;
                  })
                  .slice(perPage * (activePage - 1), perPage * activePage)
                  // TODO add proper type
                  .map((data: User) => {
                    const {
                      id,
                      fname,
                      lname,
                      lname2,
                      phone,
                      email,
                      cars,
                      isMainContact,
                      active,
                      // accessMethods,
                      // accessPoints,
                    } = data;

                    const fullName = `${fname} ${lname} ${lname2}`;
                    return {
                      label: (
                        <div className="flex items-center justify-between">
                          <Typography
                            // className="break-words truncate bg-red-400"
                            label="Name"
                            fontState="medium"
                          >
                            {fullName}
                          </Typography>

                          {expanded && (
                            <Typography label="Phone" fontState="medium">
                              {formatPhoneNumber(phone)}
                            </Typography>
                          )}

                          <Typography label="Cars" fontState="medium">
                            {cars && cars.length}
                          </Typography>

                          {/* // NOTE removed until better implementation 2022-06-29T15:14:39.000-04:00*/}
                          {/* <Typography
                            label="Priority"
                            state="info"
                            fontState="medium"
                          >
                            {isMainContact ? "1st" : "2nd"}
                          </Typography> */}

                          {/* this could be an enum  */}

                          {/* // NOTE removed until better implementation 2022-06-29T15:14:39.000-04:00*/}
                          {/* // TODO should it be either resident or visitor, or the role in the db? */}
                          {/* <Typography
                            label="Type"
                            state="info"
                            fontState="medium"
                          >
                            {isMainContact ? "Resident" : "Visitor"}
                          </Typography> */}

                          {/* // TODO alert can be active(primary/green), alert(orange), or blocked(error)*/}
                          {/* for now, an enum ??? */}
                          <Typography
                            label="Status"
                            state={active ? "info" : "error"}
                            fontState="medium"
                          >
                            {active ? "Active" : "Blocked"}
                          </Typography>
                        </div>
                      ),
                      children: (
                        <div className="p-3">
                          <div className="flex justify-end">
                            <Button
                              text="Edit User"
                              size={"xs"}
                              loading={searchParams.get("id") === id}
                              onClick={() => setSearchParams({ id })}
                            />
                          </div>
                          {/* person info */}
                          <div className="flex items-center justify-between pt-3">
                            <Typography label="Name" fontState="medium">
                              {fname ? fname : ""}
                            </Typography>

                            <Typography label="Surnames" fontState="medium">
                              {`${lname ? lname : ""} ${lname2 ? lname2 : ""}`}
                            </Typography>

                            <Typography label="Email" fontState="medium">
                              {email}
                            </Typography>

                            <Typography label="Priority" fontState="medium">
                              {isMainContact ? "1st" : "2nd"}
                            </Typography>

                            {/* // NOTE removed until implemented */}
                            {/* 
                            //TODO solve alignment issues on access and checkbox labels
                            <Typography label="Main Contact" fontState="medium">
                              <Checkbox checked={isMainContact} disabled />
                            </Typography>
                            
                            <Typography
                              label="Access Method"
                              fontState="medium"
                            >
                              <Tooltip
                                label={
                                  <div className="flex flex-wrap flex-col gap-3">
                                    <p className="self-center">
                                      Access Methods
                                    </p>

                                    <div className="flex flex-col gap-3">
                                      {accessMethods &&
                                        // TODO add proper type
                                        accessMethods.map((method: any) => {
                                          const { type, value } = method;
                                          const checkMethod = (
                                            method: string | null | undefined,
                                          ) => {
                                            if (method === "QRCODE") {
                                              return (
                                                <>
                                                  <FaQrcode />
                                                  {value}
                                                </>
                                              );
                                            }

                                            if (method === "RFID") {
                                              return (
                                                <>
                                                  <FaTag />
                                                  {value}
                                                </>
                                              );
                                            }
                                            // REVIEW not sure if its gonna be GUARDPOST
                                            if (method === "GUARDPOST") {
                                              return (
                                                <>
                                                  <FaLaptopHouse />
                                                  {value}
                                                </>
                                              );
                                            }

                                            return null;
                                          };

                                          return (
                                            <div
                                              className="flex gap-3 items-center"
                                              key={nanoid()}
                                            >
                                              {checkMethod(type)}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                }
                              >
                                <FaInfoCircle color={colors.primary} />
                              </Tooltip>
                            </Typography> */}

                            {/* // ask about what this for */}
                            {/* <div>
                  <FaImage color="#0D9E90" size={"1.5rem"} />
                </div> */}
                          </div>

                          {/* tabs | car and access points */}
                          <TabContainer
                            variant="default"
                            rootStyle="mt-3"
                            tabs={[
                              {
                                title: "Car",
                                content:
                                  cars && cars.length > 0 ? (
                                    <Table
                                      type="card"
                                      fontSize={"sm"}
                                      columns={[
                                        "Brand",
                                        "Model",
                                        "Plate",
                                        "Color",
                                        "RFID",
                                        // "Status",
                                        // "Action",
                                      ]}
                                      // TODO add proper type
                                      rowsData={cars.map((car: any) => {
                                        const {
                                          brand,
                                          model,
                                          plate,
                                          color,
                                          rfid,
                                          // status,
                                          // action,
                                        } = car;

                                        return {
                                          brand,
                                          model,
                                          plate,
                                          color: (
                                            <ColorSwatch
                                              color={color}
                                              size={20}
                                            />
                                          ),
                                          rfid,
                                          // status: (
                                          //   <Typography
                                          //     state={
                                          //       status ? "info" : "error"
                                          //     }
                                          //     fontState="medium"
                                          //   >
                                          //     {status ? "Active" : "Inactive"}
                                          //   </Typography>
                                          // ),
                                          //  ask what todo with action
                                          // action: action && (
                                          //   <FaImage
                                          //     color="#0D9E90"
                                          //     size={"1.5rem"}
                                          //   />
                                          // ),
                                        };
                                      })}
                                    />
                                  ) : (
                                    <Typography>
                                      No cars found for this user.
                                    </Typography>
                                  ),
                              },
                              // {
                              //   title: "Access Points",
                              //   content: accessPoints &&
                              //     accessPoints.length > 0 && (
                              //       <Table
                              //         type="card"
                              //         fontSize={"sm"}
                              //         columns={[
                              //           "Access Point",
                              //           "Schedule",
                              //           "Status",
                              //         ]}
                              //         rowsData={accessPoints.map(
                              //           (point) => {
                              //             const {
                              //               accessPoint,
                              //               schedule,
                              //               status,
                              //             } = point;

                              //             return {
                              //               accessPoint,
                              //               schedule,
                              //               status: (
                              //                 <Typography
                              //                   state={
                              //                     status ? "info" : "error"
                              //                   }
                              //                   fontState="medium"
                              //                 >
                              //                   {status
                              //                     ? "Active"
                              //                     : "Inactive"}
                              //                 </Typography>
                              //               ),
                              //             };
                              //           }
                              //         )}
                              //       />
                              //     ),
                              // },
                            ]}
                          />
                        </div>
                      ),
                    };
                  })
              }
            />
          </>
        )}
      </div>
      <Pagination
        classNames={{
          active: "border-2 border-primary text-primary",
        }}
        position="center"
        size="sm"
        page={activePage}
        onChange={setPage}
        total={
          // !residentsData.users
          residentsLoading
            ? 0
            : parseInt(
                (residentsData?.users
                  ? Math.ceil(residentsData?.users?.length / perPage)
                  : 0
                ).toFixed(),
              )
        }
      />
    </>
  );
};
