import React from "react";
import { Chips, Chip, InputWrapper } from "@mantine/core";
import { ChipsInputGroupProps } from "./definitions";
import { nanoid } from "nanoid";
import classNames from "classnames";

export const ChipsInputGroup = ({
  direction,
  color = "teal",
  multiple,
  radius,
  size,
  spacing,
  variant,
  value,
  onChange,
  chips,
  chipsOnClick,
  label,
  required,
  centerChips,
}: ChipsInputGroupProps) => (
  <InputWrapper
    label={label}
    required={required}
    className="w-full"
    // classNames={{
    //   root: "flex flex-col flex-grow justify-center bg-red-100",
    // }}
    // error={error}
    // classNames={{
    //   root: "flex flex-col bg-white border-primary",
    // }}
  >
    <div
      className={classNames({
        "flex flex-grow-0 justify-center": centerChips,
      })}
    >
      <Chips
        direction={direction}
        color={color}
        multiple={multiple}
        radius={radius}
        size={size}
        spacing={spacing}
        variant={variant}
        value={value}
        onChange={onChange}
      >
        {chips.map((chip) => (
          <Chip
            key={nanoid()}
            id={chip.id}
            value={chip.value}
            checked={chip.checked}
            defaultChecked={chip.defaultChecked}
            radius={chip.radius}
            variant={chip.variant}
            type={chip.type}
            size={chip.size}
            disabled={chip.disabled}
            onChange={chip.onChange}
            onClick={chip.onClick ? chip.onClick : chipsOnClick}
          >
            {chip.children}
          </Chip>
        ))}
      </Chips>
    </div>
  </InputWrapper>
);
