import { Textarea } from "@mantine/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { TextAreaInputProps } from "./definitions";

export const TextAreaInput = ({
  minRows,
  maxRows,
  autosize,
  placeholder,
  label,
  disabled,
  required,
  value,
  onChange,
  error,
}: TextAreaInputProps) => {
  const [disabledStyle, setDisabledStyle] = useState("");

  useEffect(() => {
    setDisabledStyle(classNames({ "hover:cursor-not-allowed": disabled }));
  }, []);

  return (
    <Textarea
      classNames={{
        defaultVariant: `rounded-lg shadow border-1 border-[#666666] focus:border-2 focus:border-primary ${disabledStyle}`,
      }}
      error={error}
      placeholder={placeholder}
      label={label}
      required={required}
      disabled={disabled}
      minRows={minRows}
      maxRows={maxRows}
      autosize={autosize}
      onChange={onChange}
      value={value}
    />
  );
};
