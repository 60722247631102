import React from "react";
import { ColorSwatch as ColorSwatchMantine } from "@mantine/core";
import { ColorSwatchProps } from "./definitions";

export const ColorSwatch = ({ color, size }: ColorSwatchProps) => {
  if (!color)
    return (
      <ColorSwatchMantine
        classNames={{
          overlay: "rounded-lg",
          alphaOverlay: "rounded-lg",
          shadowOverlay: "rounded-lg",
          children: "rounded-lg",
          root: "rounded-lg",
        }}
        color={""}
        size={size}
      />
    );

  return (
    <ColorSwatchMantine
      classNames={{
        overlay: "rounded-lg",
        alphaOverlay: "rounded-lg",
        shadowOverlay: "rounded-lg",
        children: "rounded-lg",
        root: "rounded-lg",
      }}
      color={color}
      size={size}
    />
  );
};
