/** Returns undefined if all values are empty. Otherwise, returns provided
 * object. */
export const objectValuesCheck = (data: any) => {
  let counter = 0;
  for (const key in data) {
    // second operand from OR to check for NaN
    if (data[key] === "" || data[key] !== data[key]) {
      counter++;
    }
  }
  if (counter === Object.keys(data).length) {
    return undefined;
  } else {
    return data;
  }
};
