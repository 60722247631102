import { Select as SelectMantine } from "@mantine/core";
import { useEffect, useState } from "react";
import { SelectInputProps } from "./definitions";

export const SelectInput = ({
  label,
  placeholder,
  options,
  disabled,
  size,
  onChange,
  creatable,
  onDropdownOpen,
  onSearchChange,
  value,
  getCreateLabel,
  onCreate,
  error,
  required,
  onInvalid,
  onInput,
  onSelect,
  nothingFound,
  className,
  clearable,
  icon,
}: SelectInputProps) => {
  const [data, setData] = useState(options);

  useEffect(() => {
    setData(options);
  }, [options]);

  return (
    <SelectMantine
      classNames={{
        input: `rounded-lg ${
          icon ? "" : "p-1 px-2"
        } shadow border-1 border-[#666666] focus:border-2 focus:border-primary`,
      }}
      icon={icon}
      clearable={clearable}
      className={className}
      nothingFound={nothingFound}
      required={required}
      label={label}
      placeholder={placeholder}
      onInvalid={onInvalid}
      onInput={onInput}
      onSelect={onSelect}
      data={data}
      onDropdownOpen={onDropdownOpen}
      onSearchChange={onSearchChange}
      disabled={disabled}
      size={size}
      creatable={creatable}
      error={error}
      filterDataOnExactSearchMatch={false}
      // getCreateLabel={creatable ? (query) => `+ Create ${query}` : undefined}
      // onCreate={
      //   creatable
      //     ? (query) => setData((current: typeof options) => [...current, query])
      //     : undefined
      // }
      onCreate={onCreate}
      onChange={onChange}
      getCreateLabel={getCreateLabel}
      value={value}
      searchable
    />
  );
};
