import { KeyIcon } from "@heroicons/react/solid";
import {
  Accordion as MAccordion,
  ActionIcon,
  Autocomplete,
  Group,
  Popover,
  Switch,
} from "@mantine/core";
import { formList, useForm, zodResolver } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { patchUser } from "src/common/api";
import { useVehiclesFetch } from "src/common/api/hooks";
import { House, User } from "src/common/api/types";
import {
  useCarOptions,
  useClusterInfo,
  useHouseQuery,
  useUsersQuery,
} from "src/common/hooks";
import { userPatchSchema } from "src/common/schemas";
import {
  formatPhoneNumber,
  getYears,
  httpErrorMessage,
  undefinedIfEmpty,
} from "src/common/utils";
import {
  Button,
  ColorInput,
  Drawer,
  Loader,
  PhoneInput,
  SelectInput,
  TextInput,
  Tooltip,
  Typography,
} from "src/components";

export const EditUserDrawer = ({
  setOpenEditDrawer,
  selectedUser,
}: {
  openEditDrawer?: boolean;
  setOpenEditDrawer: (openEditDrawer: boolean) => void;
  selectedUser: User | undefined | null;
}) => {
  const handleDrawerClose = () => {
    const param = searchParams.get("id");

    if (param) {
      // delete each query param
      searchParams.delete("id");

      // update state after
      setSearchParams(searchParams);
    }

    setOpenEditDrawer(false);
    userEditForm.reset();
  };

  const { data: housesData, refetch: housesRefetch } = useHouseQuery();
  const { refetch: usersRefetch } = useUsersQuery();
  const carOptions = useCarOptions();
  const [searchParams, setSearchParams] = useSearchParams();
  const cluster = useClusterInfo();

  const [popoverOpened, setPopoverOpened] = useState(false);

  const formattedPhone = formatPhoneNumber(selectedUser?.phone);

  const userEditForm = useForm({
    schema: zodResolver(userPatchSchema),
    initialValues: {
      fname: selectedUser?.fname || "",
      lname: selectedUser?.lname,
      lname2: selectedUser?.lname2,
      active: selectedUser?.active,
      phone: undefinedIfEmpty(formattedPhone === "None" ? "" : formattedPhone),
      email: undefinedIfEmpty(selectedUser?.email),
      /** //FIX: `formList()` was removed from `@mantine/form` on 5.x.x release.
       * Must refactor to https://mantine.dev/form/nested/#list-handlers. */
      houses: formList(
        selectedUser?.houses.map((house) => ({
          id: house.id,
          // number: house.number,
          isOwner: house.isOwner,
          key: nanoid(),
        })) ?? [{ id: "", /*number: "",*/ isOwner: false, key: nanoid() }],
      ),
      /** //FIX: `formList()` was removed from `@mantine/form` on 5.x.x release.
       * Must refactor to https://mantine.dev/form/nested/#list-handlers. */
      cars: formList(
        selectedUser?.cars.map((car) => ({
          id: car?.id,
          brand: car?.brand,
          model: car?.model,
          plate: car?.plate,
          color: car?.color,
          year: car?.year.toString(),
          rfid: car?.rfid,
          key: nanoid(),
        })) ?? [
          {
            id: "",
            brand: "",
            model: "",
            plate: "",
            color: "",
            year: "",
            rfid: "",
            key: nanoid(),
          },
        ],
      ),
    },
  });

  const [plate, setPlate] = useState("");
  const [debouncedPlate] = useDebouncedValue(plate, 250);
  const [{ data: vehicleData, loading: vehicleLoading }] = useVehiclesFetch({
    plate: debouncedPlate,
  });

  /** Data for Autocomplete component. */
  const vehicleOptions =
    !vehicleLoading && vehicleData
      ? vehicleData.data.map((vehicle: any) => ({
          value: vehicle.plate,
          id: vehicle.id,
          plate: vehicle.plate,
          color: vehicle.color,
          model: vehicle.model,
          brand: vehicle.brand,
          year: vehicle.year,
          rfid: vehicle.rfid,
        }))
      : [];

  const { mutate: submitPatchUser, isLoading: patchUserLoading } = useMutation(
    patchUser,
    {
      onSuccess: () => {
        usersRefetch();
        housesRefetch();
        userEditForm.reset();
        handleDrawerClose();
        showNotification({
          title: "Success",
          message: "User was edited successfully",
          color: "green",
        });
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    },
  );

  const housesFields = userEditForm.values.houses.map((item, index) => (
    <Group
      key={item.key}
      mt="xs"
      className={classNames({ "bg-hover": !(index % 2 === 0) })}
    >
      {/* <TextInput
        required
        label="House Number"
        {...userForm.getListInputProps("houses", index, "number", {
          type: "input",
        })}
      /> */}
      <SelectInput
        label="House Number"
        required
        key={`${item.key}-select`}
        // label="House Number"
        options={
          !housesData
            ? []
            : housesData?.houses?.map((house: House) => {
                return { value: house.id, label: house.number };
              })
        }
        onDropdownOpen={() => housesRefetch()}
        {...userEditForm.getListInputProps("houses", index, "id", {
          type: "input",
        })}
        value={item.id}
      />
      <Switch
        key={`${item.key}-switch`}
        onLabel="Yes"
        offLabel="No"
        size="md"
        label="Is Owner?"
        className="pt-6"
        classNames={{ input: "cursor-pointer" }}
        color="teal"
        {...userEditForm.getListInputProps("houses", index, "isOwner", {
          type: "checkbox",
        })}
      />
      <Tooltip
        key={`${item.key}-tooltip`}
        label="Disconnect House"
        className="bg-error"
      >
        <ActionIcon
          key={`${item.key}-action`}
          color="red"
          variant="hover"
          onClick={() => userEditForm.removeListItem("houses", index)}
        >
          <FaTimesCircle />
        </ActionIcon>
      </Tooltip>
    </Group>
  ));

  const carsFields = userEditForm.values.cars.map((item, index) => (
    // <Group key={item.key} mt="xs">
    <div
      className={`flex flex-col gap-y-2 p-2 ${classNames({
        "bg-hover": !(index % 2 === 0),
      })}`}
      key={`${item.key}-div`}
    >
      {/* <TextInput
        required
        label="House Number"
        {...userForm.getListInputProps("houses", index, "number", {
          type: "input",
        })}
      /> */}
      <div className="flex justify-end">
        <Tooltip label="Disconnect Car" className="bg-error">
          <ActionIcon
            key={"action" + item.key}
            color="red"
            variant="hover"
            onClick={() => userEditForm.removeListItem("cars", index)}
          >
            <FaTimesCircle />
          </ActionIcon>
        </Tooltip>
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <Tooltip disabled={!item.id} label="Not editable" className="bg-error">
          <TextInput
            required
            label="License Plate"
            readOnly={!!item.id}
            // disabled={!!item.id} // disabled if vehicle is in db record
            {...userEditForm.getListInputProps("cars", index, "plate", {
              type: "input",
            })}
          />
        </Tooltip>
        <Tooltip
          label={
            <div className="flex">
              <p className="text-white">
                Can only be reassigned on Access Keys
              </p>
              <KeyIcon className="h-7 w-7 self-center pb-2 text-white" />
            </div>
          }
        >
          <TextInput label="Access Key" readOnly value={item.rfid} />
        </Tooltip>
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <SelectInput
          key={"brand" + item.key}
          placeholder="Brand"
          nothingFound="No brand found"
          label="Brand"
          required
          options={
            carOptions.isSuccess && carOptions.data
              ? carOptions.data.carBrands.map((brand: any) => {
                  return `${brand.name}`;
                })
              : []
          }
          {...userEditForm.getListInputProps("cars", index, "brand", {
            type: "input",
          })}
          value={userEditForm.values.cars[index]?.brand}
        />

        <SelectInput
          key={"model" + item.key}
          placeholder="Model"
          label="Model"
          required
          nothingFound="No models found"
          disabled={userEditForm.values.cars[index]?.brand === " "}
          /*eslint-disable*/
          options={
            !carOptions.data
              ? []
              : [
                  ...carOptions.data?.carBrands
                    .filter(
                      (carBrand: any) =>
                        carBrand.name ===
                        userEditForm.values.cars[index]?.brand,
                    )
                    .map((carBrand: any) => {
                      return carBrand?.brandModels?.map((model: any) => {
                        return model.name;
                      });
                    })
                    .flat(),
                ]
          }
          /*eslint-enable*/
          {...userEditForm.getListInputProps("cars", index, "model", {
            type: "input",
          })}
          value={userEditForm.values.cars[index]?.model}
        />
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <SelectInput
          required
          placeholder={
            userEditForm.values.cars[index].year
              ? userEditForm.values.cars[index].year
              : "Year"
          }
          label="Year"
          nothingFound="Invalid year"
          options={getYears().map((year) => year.toString())}
          {...userEditForm.getListInputProps("cars", index, "year", {
            type: "input",
          })}
          value={userEditForm.values.cars[index].year}
        />
        <ColorInput
          required
          label="Color"
          {...userEditForm.getListInputProps("cars", index, "color", {
            type: "input",
          })}
          value={userEditForm.values.cars[index].color}
        />
      </div>
      {!item.id && (
        <Typography state="error" className="text-center">
          Make sure License Plate is correct, it can&apos;t be edited once
          submitted.
        </Typography>
      )}
      {/* </Group> */}
    </div>
  ));

  const errorsFound = Object.keys(userEditForm.errors).length > 0;

  return (
    <Drawer
      className="overflow-y-auto p-10"
      // opened={searchParams.get("id") !== null}
      opened={selectedUser !== null && searchParams.get("id") !== null}
      onFocus={() => setOpenEditDrawer(true)}
      // opened={selectedUser !== undefined}
      onClose={() => handleDrawerClose()}
      position="right"
      size="30%"
      withCloseButton
    >
      <Drawer.Header title="Edit User" />

      {!selectedUser ? (
        // REVIEW don't think this is needed
        <div className="mt-5 flex items-center justify-center">
          <Loader size={"lg"} />
        </div>
      ) : (
        <form
          id="userForm"
          noValidate // to avoid "An invalid form control with name='' is not focusable." when accordion folded
          onSubmit={userEditForm.onSubmit((data) => {
            // Note custom body, to include user id and casting years
            const body = {
              ...data,
              id: selectedUser.id,
              clusterId: cluster.id,
              cars: data.cars.map((car) => {
                return { ...car, year: parseInt(car.year) };
              }),
            };
            // @ts-ignore
            return submitPatchUser(body);
          })}
        >
          <MAccordion iconPosition="right" initialItem={0}>
            <MAccordion.Item
              label={
                <Typography fontState="semibold" type="regular">
                  Info
                </Typography>
              }
            >
              <div className="flex flex-col gap-y-2">
                <TextInput
                  form="userForm"
                  label="First Name"
                  required
                  {...userEditForm.getInputProps("fname")}
                />
                <TextInput
                  form="userForm"
                  label="Last Name"
                  required
                  {...userEditForm.getInputProps("lname")}
                />
                <TextInput
                  form="userForm"
                  label="Second Last Name"
                  {...userEditForm.getInputProps("lname2")}
                />
                <PhoneInput
                  form="userForm"
                  label="Phone Number"
                  {...userEditForm.getInputProps("phone")}
                />
                <TextInput
                  form="userForm"
                  label="Email"
                  {...userEditForm.getInputProps("email")}
                />
                {/* <Switch
                      size="md"
                      className="p-5 flex justify-start"
                      label="Toggle Active status"
                      color="teal"
                      {...userForm.getInputProps("active", { type: "checkbox" })}
                    /> */}
              </div>
            </MAccordion.Item>
            <MAccordion.Item
              label={
                <Typography fontState="semibold" type="regular">
                  Houses ({userEditForm.values.houses.length})
                </Typography>
              }
            >
              <div className="mt-5 flex justify-center">
                <Tooltip label="Adds existing house for this record">
                  <Button
                    text="Add House"
                    variant="outlined"
                    onClick={() =>
                      userEditForm.addListItem("houses", {
                        id: "",
                        // number: "",
                        isOwner: false,
                        key: nanoid(),
                      })
                    }
                  />
                </Tooltip>
              </div>
              <div>{housesFields}</div>
            </MAccordion.Item>
            <MAccordion.Item
              label={
                <Typography fontState="semibold" type="regular">
                  Cars ({userEditForm.values.cars.length})
                </Typography>
              }
            >
              <div className="mt-5 flex justify-center gap-x-3">
                <Tooltip label="Searches existing car by license plate">
                  <Popover
                    opened={popoverOpened}
                    onClose={() => setPopoverOpened(false)}
                    withArrow
                    position="left"
                    classNames={{ popover: "w-full h-[50%]" }}
                    target={
                      <Button
                        text="Add Vehicle"
                        variant="outlined"
                        onClick={() => setPopoverOpened(true)}
                      />
                    }
                  >
                    <Autocomplete
                      label="License Plate"
                      limit={4}
                      data={vehicleOptions}
                      placeholder="Search by plate"
                      nothingFound={
                        !vehicleLoading && vehicleData?.total === 0 ? (
                          "No results"
                        ) : (
                          <div className="flex justify-center">
                            <Loader />
                          </div>
                        )
                      }
                      value={plate}
                      onChange={(value) => setPlate(value)}
                      onItemSubmit={(item) => {
                        userEditForm.addListItem("cars", {
                          id: item.id,
                          brand: item.brand,
                          model: item.model,
                          plate: item.plate,
                          year: item.year.toString(),
                          color: item.color,
                          rfid: item.rfid,
                          key: nanoid(),
                        });
                        setPlate("");
                        setPopoverOpened(false);
                      }}
                    />
                  </Popover>
                </Tooltip>

                <Typography
                  fontState="semibold"
                  type="regular"
                  className="flex flex-col justify-center"
                >
                  or
                </Typography>
                <Tooltip label="For cars that don't exist on SESAM system">
                  <Button
                    text="Create Vehicle"
                    // className="w-[40%]"
                    variant="outlined"
                    onClick={() =>
                      userEditForm.addListItem("cars", {
                        id: "",
                        brand: "",
                        model: "",
                        plate: "",
                        year: "",
                        color: "",
                        rfid: "",
                        key: nanoid(),
                      })
                    }
                  />
                </Tooltip>
              </div>
              {carsFields}
            </MAccordion.Item>
          </MAccordion>
          {errorsFound && (
            <div className="mt-2 text-center">
              <Typography state="error" className="text-center">
                Errors Found. Make sure required values are provided and
                correct.
              </Typography>
            </div>
          )}
          <div className="mt-5 flex grow justify-center ">
            <Button
              form="userForm"
              text="Submit"
              type="submit"
              color={userEditForm.errors.length ? "error" : "info"} // FIX can't conditionally set color?
              loading={patchUserLoading}
              className="grow"
            />
          </div>
          {process.env.NODE_ENV === "development" && (
            <details className="olan">
              <pre>{JSON.stringify(selectedUser, null, 2)}</pre>
            </details>
          )}
        </form>
      )}
      {/* <pre>{JSON.stringify(selectedUser, null, 2)}</pre>
        <TextInput value={value} onChange={(e) => setValue(e.target.value)} /> */}
      {/* <UserEditForm user={selectedUser} /> */}
    </Drawer>
  );
};
