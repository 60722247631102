import { InputWrapper, TextInputProps } from "@mantine/core";
import { useId } from "react";
import InputMask from "react-input-mask";

// TODO
// REVIEW perhaps implement with a more recent masking library
/** Masked input meant for US/PR phone numbers.  */
export const PhoneInput = (
  props: TextInputProps & React.RefAttributes<HTMLInputElement>,
) => {
  const { label, error, required, className, placeholder, ...rest } = props;
  const id = useId();
  return (
    <InputWrapper
      id={id}
      label={label ?? "Phone Number"}
      required={required}
      error={error}
      classNames={{
        root: "flex flex-col bg-white border-primary",
      }}
    >
      <InputMask
        id={id}
        mask="(999) 999-9999"
        placeholder={placeholder ?? "(###) ###-####"}
        type="tel"
        // NOTE classNames here try to replicate the design system for <TextInput />
        className={`rounded-lg border-[0.1em] border-solid border-black p-1.5
        text-sm shadow focus:outline-primary ${className}`}
        // pattern={`^\(?\d{3}?\)?[\s]?\d{3}[-\s]?\d{4}[-\s]?$`}
        // @ts-ignore:next-line
        maskChar="_"
        {...rest}
      />
    </InputWrapper>
  );
};
