import { atom, useAtom } from "jotai";

export const defaultKeysQueryOptions = {
  page: 1,
  perPage: 10,
  type: undefined as
    | undefined
    | "ALWAYSACTIVE"
    | "BYPERIOD"
    | "BYSCHEDULE"
    | "BYUSES",
  accessPoint: undefined as string | undefined,
  user: undefined as string | undefined,
  key: undefined as string | undefined,
};

const keysAtom = atom(defaultKeysQueryOptions);

/** Used to share the same query options for access keys data grid filters. */
export const useAccessKeyStore = () => useAtom(keysAtom);
