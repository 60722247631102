import useAxios from "axios-hooks";
import { baseURL } from "src/common/api";
import { headersAuth } from "src/common/auth";
import { useClusterInfo } from "src/common/hooks";

interface IFetchVehicle {
  /** Plate will fetch for the license plate. Endpoint expects this exact query param key. */
  plate: string;

  /** Cluster id. Endpoint expects this exact query param key. */
  cid?: string;

  /** User id. Endpoint expects this exact query param key. */
  uid?: string | null;
}
export function useVehiclesFetch({ cid, plate, uid }: IFetchVehicle) {
  const cluster = useClusterInfo();
  if (!cid) cid = cluster.id;
  // const [{ data, loading, error }, refetch] = useAxios({
  return useAxios(
    {
      baseURL,
      url: "/vehicles",
      params: { cid, plate, uid },
      headers: headersAuth(),
    },
    { manual: !plate && !uid, useCache: false },
  );
}

interface IFetchUser {
  /** User id to fetch. Endpoint expects this exact query param key.*/
  uid: string | null;

  /** Cluster id. Endpoint expects this exact query param key. */
  cid?: string;
}
export function useUserFetch({ cid, uid }: IFetchUser) {
  const cluster = useClusterInfo();
  if (!cid) cid = cluster.id;
  return useAxios(
    {
      baseURL,
      url: "/users",
      params: { cid, uid },
      headers: headersAuth(),
    },
    { manual: !uid, useCache: false },
  );
}

interface IFetchAccessKey {
  /** Access key id to fetch*/
  kid: string | null;
}
export function useAccessKeyFetch({ kid }: IFetchAccessKey) {
  return useAxios(
    {
      baseURL,
      url: `/access-keys/${kid}`,
      headers: headersAuth(),
    },
    { manual: !kid, useCache: false },
  );
}

interface IFetchAuthVisitor {
  /** Auth visitor id to fetch*/
  aid: string | null;
}
export function useAuthVisitorFetch({ aid }: IFetchAuthVisitor) {
  return useAxios(
    {
      baseURL,
      url: `/auth-visitors/${aid}`,
      headers: headersAuth(),
    },
    { manual: !aid, useCache: false },
  );
}
