import { FaSync } from "react-icons/fa";
import { Button, Tooltip } from "src/components";

/** Handles refetching data from server with a button. Meant as a single
 * interface for this common operation across pages. */
export const RefreshButton = ({
  isRefreshing,
  refetchMethod,
}: {
  isRefreshing: boolean;
  refetchMethod: () => void;
}) => {
  return (
    <Tooltip
      disabled={isRefreshing}
      position="right"
      label="Synchronizes data with server"
    >
      <Button
        size="sm"
        text={isRefreshing ? "Fetching" : "Refresh"}
        icon={<FaSync />}
        loading={isRefreshing}
        iconPosition="left"
        onClick={() => refetchMethod()}
      />
    </Tooltip>
  );
};
