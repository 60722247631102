import classNames from "classnames";
import { useExpandStore } from "../common/store";
import { ExpandCard } from "./assets/icons";
import { ContentCardProps } from "./definitions";

export const Card = ({ children, expandable, className }: ContentCardProps) => {
  const [expanded, setExpanded] = useExpandStore();

  return (
    <div className={`relative rounded-lg bg-white p-5 shadow-xl ${className}`}>
      {expandable && (
        <button
          className="absolute left-[-1.5em] top-[50%] grid aspect-square place-items-center rounded-lg bg-white px-2 py-4 shadow-xl hover:bg-gray-200"
          onClick={() => setExpanded(!expanded)}
        >
          <ExpandCard
            className={`${classNames({
              "rotate-180": expanded,
            })}`}
            fill="black"
          />
        </button>
      )}

      {children}
    </div>
  );
};
