import React, { useEffect, useState } from "react";
import { Table as TableMantine } from "@mantine/core";
import { TableProps } from "./definitions";
import { nanoid } from "nanoid";
import classNames from "classnames";

export const Table = ({
  columns,
  rowsData,
  type = "regular",
  horizontalSpacing,
  verticalSpacing,
  striped,
  highlightOnHover,
  fontSize = "md",
  tableHeaderStyle,
  sticky = false,
}: TableProps) => {
  const [typeHeaderStyle, setTypeHeaderStyle] = useState("");
  const [typeRowStyle, setTypeRowStyle] = useState("");

  useEffect(() => {
    if (type === "regular") {
      setTypeHeaderStyle("border-[.1em] border-b-black border-t-0 border-x-0");
      setTypeRowStyle("border-[.1em] border-y-[#cccccc] border-x-0");
    }
    if (type === "card") {
      setTypeHeaderStyle("border-0");
      // TODO make custom css for 'card' style
      /*
          border-spacing: 0 0.5em;
          border-collapse: separate;
      */
      setTypeRowStyle("rounded-lg shadow");
    }
  }, []);

  // TODO find out how to hide ID column
  const ths = (
    <tr className={`${typeHeaderStyle}`}>
      {columns.map((column) => (
        <th
          key={nanoid()}
          className={`${classNames({ "!border-0": type === "card" })}`}
        >
          {column}
        </th>
      ))}
    </tr>
  );

  const rows = rowsData.map((data) => (
    //   TODO ask if row id is needed on table rows
    // Object.keys(data).find(key=>key==="id")
    // data.id ? data.id : nanoid()
    <tr key={nanoid()} className={`${typeRowStyle}`}>
      {Object.values(data).map((value) => (
        <td
          key={nanoid()}
          className={`${classNames({ "!border-0": type === "card" })}`}
        >
          {value}
        </td>
      ))}
    </tr>
  ));

  return (
    <TableMantine
      horizontalSpacing={horizontalSpacing}
      verticalSpacing={verticalSpacing}
      striped={striped}
      highlightOnHover={highlightOnHover}
      fontSize={fontSize}
    >
      {/* <caption>Some elements from periodic table</caption> */}
      {/* if need the header sticky: className="sticky -top-5 bg-white z-10" */}
      <thead
        className={`${classNames([
          tableHeaderStyle,
          sticky && "sticky -top-5 z-10 bg-white",
        ])}`}
      >
        {ths}
      </thead>
      <tbody>{rows}</tbody>
      {/* <tfoot>{ths}</tfoot> */}
    </TableMantine>
  );
};
