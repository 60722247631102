import dayjs from "dayjs";

/** Returns a list of years, from `startYear` to `endYear` */
export function getYears({
  startYear = 1884,
  endYear = dayjs().add(2, "years").year(),
  shifted = true,
}: {
  /** Integer number representing starting year. Should be less than the ending year. */
  startYear?: number;

  /** Integer number for year stop. Should be greater than the starting year. */
  endYear?: number;

  /** Determines how the orders of the list items should return. */
  shifted?: boolean;
  // Note the `={}` is to accept nothing as default value, otherwise at
  // invocation one would have to pass an empty object
} = {}) {
  if (startYear > endYear) {
    throw new Error("startYear should be less than endYear");
  }
  if (!Number.isInteger(startYear) || !Number.isInteger(endYear)) {
    throw new Error("Years should be integers");
  }
  const years = [];
  for (let i = startYear | 0; i <= endYear; i++) {
    // `.unshift()` to get years from newest to oldest
    if (shifted) years.unshift(i);
    else years.push(i);
  }
  return years;
}
