import { Switch } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { postHouse } from "src/common/api";
import { useClusterInfo, useHouseQuery } from "src/common/hooks";
import { HouseSchema, houseSchema } from "src/common/schemas";
import { httpErrorMessage } from "src/common/utils";
import { Button, Drawer, TextInput } from "src/components";

export const CreateHouseDrawer = ({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
}) => {
  const cluster = useClusterInfo();
  const { refetch: houseRefetch } = useHouseQuery();

  const houseForm = useForm<HouseSchema>({
    schema: zodResolver(houseSchema),
    initialValues: {
      clusterId: cluster.id,
      number: "",
      address: "",
      isAdmin: false,
    },
  });

  const { mutate: submitPostHouse, isLoading: postHouseLoading } = useMutation(
    postHouse,
    {
      onSuccess: () => {
        houseForm.reset();
        setOpenDrawer(false);
        showNotification({
          title: "Success",
          message: "House created successfully",
          color: "green",
        });
        houseRefetch();
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    },
  );

  const handleDrawerClose = () => {
    houseForm.reset();
    setOpenDrawer(false);
  };

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={openDrawer}
      onClose={handleDrawerClose}
      position="right"
      size={"30%"}
      withCloseButton
    >
      <Drawer.Header title="Create House" />

      <form
        id="houseForm"
        noValidate
        className="flex flex-col gap-y-2"
        onSubmit={houseForm.onSubmit((data) => {
          // console.log("data", data);
          // console.log("SUBMIT", data);
          submitPostHouse(data);
        })}
      >
        <TextInput
          required
          form="houseForm"
          label="House Number"
          {...houseForm.getInputProps("number")}
        />
        <TextInput
          required
          form="houseForm"
          label="Address"
          {...houseForm.getInputProps("address")}
        />
        <Switch
          label="Is it from Administration?"
          form="houseForm"
          size="md"
          required
          onLabel="Yes"
          offLabel="No"
          color="green"
          {...houseForm.getInputProps("isAdmin", { type: "checkbox" })}
        />
        <div className="mt-5 flex justify-center">
          <Button
            loading={postHouseLoading}
            text="Submit"
            type="submit"
            form="houseForm"
            className="grow"
          />
        </div>
      </form>
    </Drawer>
  );
};
