import {
  Badge,
  LoadingOverlay,
  Pagination,
  Select,
  Switch,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { ColumnDef } from "@tanstack/react-table";
import { DataGrid } from "mantine-data-grid";
import { useEffect, useState } from "react";
import { FaFilter, FaKey, FaSearch } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { useAccessKeyFetch } from "src/common/api/hooks";
import { AccessKey } from "src/common/api/types";
import { RefreshButton } from "src/common/features";
import {
  useAccessKeys,
  useAccessPoints,
  useUsersQuery,
} from "src/common/hooks";
import { defaultKeysQueryOptions, useAccessKeyStore } from "src/common/store";
import {
  Button,
  Card,
  CardsContainer,
  Loader,
  SelectInput,
  TextInput,
  Tooltip,
} from "src/components";
import { CreateAccessKeyDrawer, EditAccessKeyDrawer } from "./features/drawers";
import { ToggleAccessKeyModal } from "./features/modals/ToggleAccessModal";
import { checkInMethod, checkTooltipIcon } from "./features/utils";

export const AccessKeys = () => {
  const [openGiveAccessDrawer, setOpenGiveAccessDrawer] = useState(false);
  const [openEditAccessDrawer, setOpenEditAccessDrawer] = useState(false);

  const [searchParams] = useSearchParams();
  const idFromParam = searchParams.get("id");

  const [queryOptions, setQueryOptions] = useAccessKeyStore();
  const { isFetching: isFetchingKeys, refetch: refetchKeys } =
    useAccessKeys(queryOptions);

  const [{ data: selectedKey, loading: selectedKeyLoading }] =
    useAccessKeyFetch({
      kid: idFromParam,
    });

  /** Set default options when page unmounts */
  useEffect(() => {
    return setQueryOptions(defaultKeysQueryOptions);
  }, []);

  return (
    <CardsContainer className="pl-20">
      <div className="h-[92%]">
        <CreateAccessKeyDrawer
          openDrawer={openGiveAccessDrawer}
          setOpenDrawer={setOpenGiveAccessDrawer}
        />

        {/* // UGLY the overlay and Edit implementation are not ideal */}
        {selectedKeyLoading && openEditAccessDrawer === false && (
          <LoadingOverlay visible loader={<Loader size="lg" />} />
        )}
        {selectedKey && !selectedKeyLoading && (
          <EditAccessKeyDrawer
            selectedKey={selectedKey}
            openEditDrawer={openEditAccessDrawer}
            setOpenEditDrawer={setOpenEditAccessDrawer}
          />
        )}

        <div className="flex justify-between gap-3">
          <RefreshButton
            isRefreshing={isFetchingKeys}
            refetchMethod={refetchKeys}
          />

          <Button
            text="Give Access"
            icon={<FaKey />}
            iconPosition="left"
            onClick={() => setOpenGiveAccessDrawer(true)}
          />
        </div>

        <Card className="h-full w-full overflow-y-auto">
          <AccessManagementDataTable />
        </Card>
      </div>
    </CardsContainer>
  );
};

const AccessManagementDataTable = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const [selectKey, setSelectKey] = useState<AccessKey | undefined>();
  const [, setSearchParams] = useSearchParams();

  const accessPoints = useAccessPoints();

  const [queryOptions, setQueryOptions] = useAccessKeyStore();

  const [keyValue, setKeyValue] = useState("");
  const [debouncedKey] = useDebouncedValue(keyValue, 1000);
  useEffect(() => {
    setQueryOptions((prev) => ({ ...prev, key: debouncedKey, page: 1 }));
  }, [debouncedKey]);

  const { data: keysData, isLoading: keysLoading } =
    useAccessKeys(queryOptions);

  const { data: usersData } = useUsersQuery();

  const handleSelection = (key: AccessKey) => {
    setSelectKey(key);
    setToggleModal(true);
  };

  // REVIEW: don't check for undefined
  // do another case instead (eg. if undefined, show no results)
  if (keysLoading || !keysData || keysData === undefined) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  const tableColumns: ColumnDef<AccessKey>[] = [
    {
      size: 250,
      header: "User",
      accessorKey: "user",
      accessorFn: (row) => {
        if (!row.user) return "No User";
        return `${row.user.fname} ${row.user.lname} ${row.user.lname2}`;
      },
      cell({ renderValue, getValue }) {
        if (getValue() === "No User") {
          return (
            <Badge variant="filled" color="red" className="normal-case">
              {renderValue<string>()}
            </Badge>
          );
        }
        return renderValue<string>();
      },
    },
    {
      size: 250,
      accessorFn: (row) => {
        if (!row.accessPoint) return "No Access Point";
        return row.accessPoint.name;
      },
      header: "Access Point",
    },
    {
      size: 250,
      accessorFn: (row) => {
        if (!row.givenBy) return "No Given By";
        return `${row.givenBy.fname} ${row.givenBy.lname} ${row.givenBy.lname2}`;
      },
      header: "Authorized By",
    },
    {
      size: 200,
      accessorKey: "key",
      header: "Key",
      cell: (cell) => {
        const key = cell.getValue<string>();
        const entryType = cell.row.original.entryType;

        return (
          <Tooltip
            label={
              <div className="flex flex-col flex-wrap gap-3">
                <p className="self-center">Access Methods</p>

                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-3">
                    {checkInMethod(entryType, key)}
                  </div>
                </div>
              </div>
            }
          >
            <div className="flex flex-row justify-evenly gap-x-2 px-5">
              {checkTooltipIcon(entryType)}
              {entryType === "RFID" && <p>{key}</p>}
            </div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Access Type",
    },
    {
      size: 200,
      header: "Actions",
      enableSorting: true,
      accessorKey: "active",
      cell: (cell) => {
        const accessKey = cell.row.original;
        const { id, active, entryType } = cell.row.original;
        return (
          <div className="flex justify-between gap-x-4 px-1 ">
            <Button
              size="sm"
              variant="outlined"
              text={active ? "Revoke" : "Grant"}
              color={active ? "error" : "info"}
              onClick={() => handleSelection(accessKey)}
            />
            {entryType === "RFID" && (
              <Button
                size="sm"
                text="Edit"
                onClick={() => setSearchParams({ id })}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ToggleAccessKeyModal
        isOpen={toggleModal}
        onClose={() => setToggleModal(false)}
        accessKey={selectKey}
      />

      <div className="flex flex-row gap-3 pb-3">
        <SelectInput
          clearable
          size="xs"
          icon={<FaSearch size={14} />}
          placeholder="Search for User"
          value={queryOptions.user}
          onChange={(value) =>
            setQueryOptions((prev) => ({ ...prev, user: value, page: 1 }))
          }
          options={
            usersData
              ? usersData.users.map((user: any) => {
                  return {
                    label: `${user.fname} ${user.lname} ${user.lname2}`,
                    value: user.id,
                  };
                })
              : [{ label: "loading...", value: "error" }]
          }
        />

        <Switch
          size="sm"
          label="Show No User Keys"
          className="px-3"
          classNames={{ input: "cursor-pointer" }}
          color="teal"
          onChange={(event) => {
            return setQueryOptions((prev) => ({
              ...prev,
              user: event.currentTarget.checked ? "null" : undefined,
              page: 1,
            }));
          }}
        />

        <SelectInput
          clearable
          icon={<FaFilter size={14} />}
          size="xs"
          placeholder="Filter by Access Point"
          value={queryOptions.accessPoint}
          onChange={(value) =>
            setQueryOptions((prev) => ({
              ...prev,
              accessPoint: value,
              page: 1,
            }))
          }
          options={accessPoints.map((ap: any) => ({
            value: ap.id,
            label: ap.name,
          }))}
        />

        {/* TODO: removed until implementation is defined on backend */}
        {/* <SelectInput
          size="xs"
          clearable
          icon={<FaSearch size={14} />}
          placeholder="Search by Authorizer"
          options={
            usersData
              ? usersData.users.map((user: any) => {
                  return {
                    label: `${user.fname} ${user.lname} ${user.lname2}`,
                    value: user.id,
                  };
                })
              : [{ label: "loading...", value: "error" }]
          }
        /> */}

        <TextInput
          size="xs"
          icon={<FaSearch size={14} />}
          placeholder="Search by Key"
          value={keyValue}
          onChange={({ currentTarget }) => setKeyValue(currentTarget.value)}
        />

        <SelectInput
          size="xs"
          clearable
          icon={<FaSearch size={14} />}
          placeholder="Search by Type"
          value={queryOptions.type}
          onChange={(value) =>
            setQueryOptions((prev) => ({ ...prev, type: value, page: 1 }))
          }
          options={[
            { label: "Always Active", value: "ALWAYSACTIVE" },
            { label: "By Period", value: "BYPERIOD" },
            { label: "By Schedule", value: "BYSCHEDULE" },
            { label: "By Uses", value: "BYUSES" },
          ]}
        />
      </div>

      <DataGrid
        data={keysData.accessKeys}
        highlightOnHover
        withFixedHeader
        withColumnResizing
        noFlexLayout
        iconColor="teal"
        columns={tableColumns}
        classNames={{
          thead:
            "bg-gray-100/30 backdrop-blur-sm rounded-lg p-2 z-50 border-none",
        }}
      />

      <div className="flex flex-row justify-center gap-3">
        <label htmlFor="jump-page">Jump to page</label>
        <Select
          id="jump-page"
          searchable
          nothingFound="Page is not in range"
          data={generatePageList(keysData.totalPages)}
          value={queryOptions.page.toString()}
          onChange={(value) =>
            setQueryOptions((prev) => ({ ...prev, page: Number(value) }))
          }
        />

        <Pagination
          classNames={{
            active: "border-2 border-primary text-primary",
          }}
          position="center"
          size="sm"
          total={keysData.totalPages}
          siblings={1}
          page={queryOptions.page}
          onChange={(value) =>
            setQueryOptions((prev) => ({ ...prev, page: value }))
          }
        />
      </div>
    </>
  );
};

const generatePageList = (length: number) => {
  const array = [];
  for (let i = 1; i < length + 1; i++) {
    array.push(i.toString());
  }
  return array;
};
