import { LoadingOverlay, Pagination } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";
import {
  FaCheck,
  FaCheckCircle,
  FaExternalLinkAlt,
  FaHouseUser,
  FaTimes,
} from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
import { CardsContainer } from "src//components/CardsContainer";
import { useUserFetch } from "src/common/api/hooks";
import { House, User } from "src/common/api/types";
import colors from "src/common/colors";
import { RefreshButton } from "src/common/features";
import { useHouseQuery } from "src/common/hooks";
import { useExpandStore } from "src/common/store";
import { formatPhoneNumber } from "src/common/utils";
import {
  Accordion,
  Button,
  Card,
  Loader,
  Tab as TabContainer,
  Table,
  TextInput,
  Tooltip,
  Typography,
} from "src/components";
import { CreateHouseDrawer } from "src/pages/houses/features/drawers/CreateHouseDrawer";
import { EditUserDrawer } from "src/pages/users/features/drawers";

export const Houses = () => {
  const {
    data: housesData,
    isLoading: housesLoading,
    isFetching,
    refetch,
  } = useHouseQuery();
  const [, setExpanded] = useExpandStore();
  const [houseNumber, setHouseNumber] = useState("");
  const [mainContact, setMainContact] = useState("");
  const [debouncedHouseNumber] = useDebouncedValue(houseNumber, 500);
  const [debouncedMainContact] = useDebouncedValue(mainContact, 500);

  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);
  const [openCreateHouseDrawer, setOpenCreateHouseDrawer] = useState(false);

  const [searchParams] = useSearchParams();
  const idFromParam = searchParams.get("id");
  const [{ data: selectedUser, loading: selectedUserLoading }] = useUserFetch({
    uid: idFromParam,
  });
  // pagination logic thanks to https://ilikekillnerds.com/2020/09/how-to-paginate-an-array-in-javascript/
  const [activePage, setPage] = useState(1);
  const perPage = 7;

  if (housesLoading || !housesData) {
    // TODO should be its own reusable loader component
    return (
      <CardsContainer className="pl-20">
        <Card className="h-full w-full">
          <div className="flex h-full items-center justify-center">
            <Loader size="lg" />
          </div>
        </Card>
      </CardsContainer>
    );
  }

  const filteredHouses = housesData.houses?.filter((house: House) => {
    // i don't know if this is the best implementation of the search, if anything is better than this feel free to change it

    if (!debouncedHouseNumber && !debouncedMainContact) {
      return house;
    }

    if (debouncedHouseNumber) {
      return house.number.includes(debouncedHouseNumber);
    }

    if (debouncedMainContact) {
      const mainContactFullName = `${house?.mainContact[0]?.fname} ${house?.mainContact[0]?.lname} ${house?.mainContact[0]?.lname2}`;

      return mainContactFullName.includes(debouncedMainContact);
    }

    return house;
  });

  return (
    <CardsContainer className="pl-20">
      <div>
        <CreateHouseDrawer
          openDrawer={openCreateHouseDrawer}
          setOpenDrawer={setOpenCreateHouseDrawer}
        />
        <div className="flex flex-row justify-between gap-3">
          <RefreshButton isRefreshing={isFetching} refetchMethod={refetch} />
          <Button
            text="Create House"
            icon={<FaHouseUser />}
            iconPosition="left"
            onClick={() => setOpenCreateHouseDrawer(true)}
          />
        </div>
        <Card className="h-full w-full overflow-y-auto">
          {/* // UGLY the overlay and Edit implementation are not ideal */}
          {selectedUserLoading && openEditUserDrawer === false && (
            <LoadingOverlay visible loader={<Loader size={"lg"} />} />
          )}
          {/* // UGLY the overlay and Edit implementation are not ideal */}
          {selectedUser && !selectedUserLoading && (
            <EditUserDrawer
              selectedUser={selectedUser.users[0]}
              openEditDrawer={openEditUserDrawer}
              setOpenEditDrawer={setOpenEditUserDrawer}
            />
          )}
          <div className="flex flex-wrap gap-x-3 gap-y-1">
            {/* //IDEA have this be select inputs already filled */}
            {/* I did not put the select input for houses because when empty it will reset to the previous state upon dropdown close */}
            <TextInput
              label="House Number"
              placeholder="Search by number"
              size="xs"
              onChange={(e) => {
                setHouseNumber(e.target.value);
                setPage(1);
              }}
              value={houseNumber}
            />
            <TextInput
              label="Main Contact"
              placeholder="Search by first name"
              size="xs"
              onChange={(e) => {
                setMainContact(e.target.value);
                setPage(1);
              }}
              value={mainContact}
            />
          </div>

          <div className="mt-5 h-[80%] overflow-y-auto">
            <Accordion
              onChange={() => setExpanded(true)}
              // UGLY maybe have the filter in its own variable or make a function that searches the houses data and return the new array
              items={filteredHouses
                .slice(perPage * (activePage - 1), perPage * activePage)
                .map((house: House) => {
                  const {
                    number,
                    address,
                    mainContact,
                    residents,
                    totalCars,
                    visitors,
                  } = house;

                  const mainContactFullName = mainContact.length
                    ? `${mainContact[0]?.fname} ${mainContact[0]?.lname} ${mainContact[0]?.lname2}`
                    : "No Owner set";

                  const noOwnerCheck = ({ valueRes }: { valueRes: any }) =>
                    mainContact.length === 0 ? valueRes : undefined;

                  return {
                    label: (
                      <div className="flex items-center justify-between">
                        <Typography label="House" fontState="medium">
                          {number} - {address}
                        </Typography>

                        <Typography
                          label="Main Contact"
                          fontState="medium"
                          state={noOwnerCheck({ valueRes: "error" })}
                          className={noOwnerCheck({
                            valueRes: "rounded-md bg-red-300 px-10",
                          })}
                        >
                          {mainContactFullName}
                        </Typography>

                        <Typography
                          label="Residents"
                          fontState="medium"
                          state="info"
                        >
                          {residents.length + mainContact.length}
                        </Typography>

                        <Typography
                          label="Visitors"
                          fontState="medium"
                          state="info"
                        >
                          {visitors.length}
                        </Typography>

                        <Typography label="Cars" fontState="medium">
                          {totalCars}
                        </Typography>
                      </div>
                    ),
                    children: (
                      <div className="p-3">
                        <TabContainer
                          variant="default"
                          rootStyle="mt-3"
                          tabs={[
                            {
                              title: "Residents",
                              content:
                                residents.length > 0 ? (
                                  <HouseResidentsTab
                                    residents={[...mainContact, ...residents]}
                                  />
                                ) : (
                                  <Typography>No residents</Typography>
                                ),
                            },
                            {
                              title: "Visitors",
                              content:
                                visitors.length > 0 ? (
                                  <HouseAuthorizedTab
                                    authorizedResidentData={visitors}
                                  />
                                ) : (
                                  <Typography>No visitors</Typography>
                                ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  };
                })}
            />
          </div>

          {/* //TODO: fix overflow issue on smaller computers. NTH */}
          <Pagination
            classNames={{
              active: "border-2 border-primary text-primary",
            }}
            position="center"
            size="sm"
            page={activePage}
            onChange={setPage}
            // HACK: Adding 1 for activePage, `|0` is to trim decimals
            total={(1 + filteredHouses.length / perPage) | 0}
          />
        </Card>
      </div>
    </CardsContainer>
  );
};

const HouseResidentsTab = ({ residents }: { residents: User[] }) => {
  return (
    <Table
      type="card"
      fontSize={"sm"}
      columns={[
        // "ID",
        "Contact",
        "Resident",
        "Phone",
        "Cars",
        "Authorization",
        "Status",
        // "Access",
        // "Action",
      ]}
      rowsData={residents.map((resident) => {
        const { id, isMainContact, fname, lname, lname2, phone, cars, active } =
          resident;
        const fullName = `${fname ? fname : ""} ${lname ? lname : ""} ${
          lname2 ? lname2 : ""
        }`;
        return {
          mainContact: isMainContact && (
            <FaCheckCircle color={colors.primary} size={"1rem"} />
          ),
          residentName: (
            <Tooltip label="Edit User" position="top">
              <Link className="text-primary underline " to={`/houses?id=${id}`}>
                <span className="flex flex-row">
                  {fullName} <FaExternalLinkAlt size="0.8em" className="ml-1" />
                </span>
              </Link>
            </Tooltip>
          ),
          phone: formatPhoneNumber(phone),
          cars: cars?.length,
          authorization: (
            <Typography
              state={isMainContact ? "info" : undefined}
              fontState="medium"
            >
              {isMainContact ? "1st" : "2nd"}
            </Typography>
          ),
          status: (
            <Typography state={active ? "info" : "error"} fontState="medium">
              {active ? "Active" : "Inactive"}
            </Typography>
          ),
        };
      })}
    />
  );
};

const HouseAuthorizedTab = ({
  authorizedResidentData,
}: {
  // TODO add proper type-safety
  authorizedResidentData: any;
}) => {
  return (
    <Table
      type="card"
      fontSize="sm"
      columns={["Name", "Phone", "Type", "Status"]}
      rowsData={authorizedResidentData.map((data: any) => {
        const { fname, lname, lname2, visitorType, active, phone } = data;

        return {
          name: `${fname ? fname : ""} ${lname ? lname : ""} ${
            lname2 ? lname2 : ""
          }`,
          phone: formatPhoneNumber(phone),
          type: `${visitorType ? visitorType : "N/A"}`,
          status: active ? (
            <FaCheck color={colors.primary} />
          ) : (
            <FaTimes color={colors.error} />
          ),
        };
      })}
    />
  );
};
