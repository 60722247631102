import { LoadingOverlay } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { booleanFilterFn, DataGrid, stringFilterFn } from "mantine-data-grid";
import { useState } from "react";
import { FaCheck, FaClipboardList, FaTimes } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { CardsContainer } from "src//components/CardsContainer";
import { useAuthVisitorFetch } from "src/common/api/hooks";
import colors from "src/common/colors";
import { RefreshButton } from "src/common/features";
import { useAuthVisitorsQuery } from "src/common/hooks";
import { formatPhoneNumber } from "src/common/utils";
import { Button, Card, Loader } from "src/components";
import {
  CreateAuthVisitorDrawer,
  EditAuthVisitorDrawer,
} from "./features/drawers/";

export const AuthVisitors = () => {
  const [openGiveAccessDrawer, setOpenGiveAccessDrawer] = useState(false);
  const [openDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  const [searchParams] = useSearchParams();
  const idFromParam = searchParams.get("id");

  const { isFetching: isFetchingAuth, refetch: refetchAuth } =
    useAuthVisitorsQuery();

  const [{ data: authUserData, loading: authUserLoading }] =
    useAuthVisitorFetch({
      aid: idFromParam,
    });

  return (
    <CardsContainer className="pl-20">
      <div className="h-[92%]">
        <CreateAuthVisitorDrawer
          openDrawer={openGiveAccessDrawer}
          setOpenDrawer={setOpenGiveAccessDrawer}
        />

        {/* // UGLY the overlay and Edit implementation are not ideal */}
        {authUserLoading && openEditDrawer === false && (
          <LoadingOverlay visible loader={<Loader size="lg" />} />
        )}

        {authUserData && !authUserLoading && (
          <EditAuthVisitorDrawer
            selectedUser={authUserData?.data}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenEditDrawer}
          />
        )}

        <div className="flex justify-between gap-3">
          <RefreshButton
            isRefreshing={isFetchingAuth}
            refetchMethod={refetchAuth}
          />
          <Button
            text="Add Auth Visitor"
            icon={<FaClipboardList />}
            iconPosition="left"
            onClick={() => setOpenGiveAccessDrawer(true)}
          />
        </div>

        <Card className="h-full w-full overflow-y-auto">
          <AuthVisitorsDataTable />
        </Card>
      </div>
    </CardsContainer>
  );
};

const AuthVisitorsDataTable = () => {
  const { height } = useViewportSize();

  const { data: authVisitorData, isLoading: authVisitorLoading } =
    useAuthVisitorsQuery();

  const [, setSearchParams] = useSearchParams();

  if (authVisitorLoading || !authVisitorData) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader size={"lg"} />
      </div>
    );
  }
  return (
    <DataGrid
      data={authVisitorData.authVisitors}
      withPagination
      initialState={{
        pagination: { pageSize: 20 },
      }}
      pageSizes={["10", "20", "50", "100"]}
      withSorting
      withColumnFilters
      highlightOnHover
      height={height / 1.5}
      withFixedHeader
      iconColor="teal"
      noEllipsis
      withColumnResizing
      noFlexLayout
      classNames={{
        thead:
          "bg-gray-100/30 backdrop-blur-sm rounded-lg p-2 z-50 border-none",
        pagination:
          "sticky bottom-0 bg-gray-100/30 backdrop-blur-sm rounded-lg p-2",
        // td: "text-clip overflow-visible",
      }}
      columns={[
        {
          size: 250,
          accessorFn: (row) => `${row.fname} ${row.lname} ${row.lname2}`,
          header: "Name",
          filterFn: stringFilterFn,
        },
        {
          size: 250,
          accessorFn: (row) =>
            row.houses.map((house: any) => `${house.number}`),
          header: "House Numbers",
          filterFn: stringFilterFn,
        },
        {
          accessorFn: (row) => row.grantedBy?.name,
          header: "Authorized By",
          filterFn: stringFilterFn,
        },
        {
          accessorKey: "visitorType",
          header: "Type",
          filterFn: stringFilterFn,
        },
        {
          accessorFn: (row) => formatPhoneNumber(row.phone),
          header: "Phone Number",
          filterFn: stringFilterFn,
        },
        {
          accessorKey: "active",
          header: "Status",
          filterFn: booleanFilterFn,
          enableSorting: false,
          cell: (cell) =>
            cell.getValue<boolean>() ? (
              <FaCheck color={colors.primary} />
            ) : (
              <FaTimes color={colors.error} />
            ),
        },
        {
          accessorKey: "details",
          size: 300,
          header: "Details",
          filterFn: stringFilterFn,
        },
        {
          size: 100,
          header: "Actions",
          enableSorting: true,
          filterFn: booleanFilterFn,
          cell: (cell) => {
            // const accessKey = cell.row.original;
            // const { id, active, entryType } = cell.row.original;
            const { id } = cell.row.original;
            return (
              <div key={id} className="flex justify-between gap-x-4 px-1 ">
                <Button
                  key={id}
                  size="sm"
                  text="Edit"
                  onClick={() => setSearchParams({ id })}
                />
              </div>
            );
          },
        },
      ]}
    />
  );
};
