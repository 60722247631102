import { DateRangePicker } from "@mantine/dates";
import { DateRangePickerInputProps } from "./definitions";

export const DateRangePickerInput = ({
  defaultValue,
  description,
  disabled,
  dropdownType,
  error,
  icon,
  initialLevel,
  initialMonth,
  inputFormat = "MM/DD/YYYY",
  label,
  locale,
  maxDate,
  minDate,
  placeholder,
  radius,
  range,
  required,
  size,
  variant,
  value,
  onChange,
}: DateRangePickerInputProps) => {
  //   console.log(value);

  return (
    <DateRangePicker
      classNames={{
        input:
          "rounded-lg p-1 px-2 shadow border-1 border-[#666666] focus:border-2 focus:border-primary",
      }}
      styles={{
        // weekend: { color: "red" },
        // selected: { backgroundColor: "red" },
        inRange: { backgroundColor: "#AEDEDA !important" },
        firstInRange: { backgroundColor: "#0D9E90 !important" },
        lastInRange: { backgroundColor: "#0D9E90 !important" },
      }}
      value={value}
      //   range={range}
      onChange={onChange}
      defaultValue={defaultValue}
      description={description}
      disabled={disabled}
      dropdownType={dropdownType}
      error={error}
      icon={icon}
      initialLevel={initialLevel}
      initialMonth={initialMonth}
      inputFormat={inputFormat}
      label={label}
      locale={locale}
      maxDate={maxDate}
      minDate={minDate}
      placeholder={placeholder}
      radius={radius}
      range={range}
      required={required}
      size={size}
      variant={variant}
    />
  );
};
