export const useAuth = () => {
  return sessionStorage.getItem("token");
};

/** Function that handles logout. Hook useNavigate did not work here. */
export const logout = () => {
  sessionStorage.clear();
  window.location.replace("/");
};

// TODO make better implementation for auth
// UGLY this is a get around for now, because of the nature of localStorage
export const headersAuth = (token?: string) => {
  return {
    Authorization: `Bearer ${token ? token : sessionStorage.getItem("token")}`,
  };
};
