import { KeyIcon } from "@heroicons/react/solid";
import {
  Accordion as MAccordion,
  ActionIcon,
  Group,
  Switch,
} from "@mantine/core";
import { formList, useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useMutation } from "react-query";
import { postUser } from "src/common/api";
import { House } from "src/common/api/types";
import {
  useCarOptions,
  useClusterInfo,
  useHouseQuery,
  useUsersQuery,
} from "src/common/hooks";
import { userPostSchema } from "src/common/schemas";
import { useCreatedUserStore } from "src/common/store";
import { getYears, httpErrorMessage } from "src/common/utils";
import {
  Button,
  ColorInput,
  Drawer,
  PhoneInput,
  SelectInput,
  TextInput,
  Tooltip,
  Typography,
} from "src/components";
import { VehicleSearchPopover } from "src/pages/users/features/modals";

export const CreateUserDrawer = ({
  openDrawer,
  setOpenDrawer,
  hideCarsSection,
}: {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
  hideCarsSection?: boolean;
}) => {
  const handleDrawerClose = () => {
    setOpenDrawer(false);
    userForm.reset();
  };
  const cluster = useClusterInfo();

  const { data: housesData, refetch: housesRefetch } = useHouseQuery();
  const { refetch: usersRefetch } = useUsersQuery();
  const carOptions = useCarOptions();

  const [popoverOpened, setPopoverOpened] = useState(false);

  const userForm = useForm({
    schema: zodResolver(userPostSchema),
    initialValues: {
      fname: "",
      lname: "",
      lname2: "",
      phone: "", // to make it optional
      email: "", // to make it optional
      /** //FIX: `formList()` was removed from `@mantine/form` on 5.x.x release.
       * Must refactor to https://mantine.dev/form/nested/#list-handlers. */
      houses: formList([{ id: "", isOwner: false, key: nanoid() }]),
      /** //FIX: `formList()` was removed from `@mantine/form` on 5.x.x release.
       * Must refactor to https://mantine.dev/form/nested/#list-handlers. */
      cars: formList([
        {
          id: "",
          brand: "",
          model: "",
          plate: "",
          color: "",
          year: "",
          rfid: "",
          key: nanoid(),
        },
      ]),
    },
  });
  const errorsFound = Object.keys(userForm.errors).length > 0;
  useEffect(() => {
    if (hideCarsSection) userForm.removeListItem("cars", 0);
  }, [hideCarsSection]);

  const [, setCreatedUser] = useCreatedUserStore();
  const { mutate: submitPostUser, isLoading: postUserLoading } = useMutation(
    postUser,
    {
      onSuccess: (res) => {
        console.log("res", res);
        userForm.reset();
        usersRefetch();
        handleDrawerClose();
        // Only sets "global" created user if component is used in <CreateAccessKeys />
        if (hideCarsSection) setCreatedUser(res);

        showNotification({
          title: "Success",
          message: "User was created successfully",
          color: "green",
        });
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    },
  );

  const housesFields = userForm.values.houses.map((item, index) => (
    <Group
      key={item.key}
      mt="xs"
      className={classNames({ "bg-green-50": !(index % 2 === 0) })}
    >
      <SelectInput
        label="House Number"
        key={`${item.key}-select`}
        // label="House Number"
        options={
          !housesData
            ? []
            : housesData?.houses?.map((house: House) => {
                return { value: house.id, label: house.number };
              })
        }
        onDropdownOpen={() => housesRefetch()}
        {...userForm.getListInputProps("houses", index, "id", {
          type: "input",
        })}
        value={item.id}
      />
      <Switch
        key={`${item.key}-switch`}
        onLabel="Yes"
        offLabel="No"
        size="md"
        label="Is Owner?"
        className="pt-6"
        classNames={{ input: "cursor-pointer" }}
        color="teal"
        {...userForm.getListInputProps("houses", index, "isOwner", {
          type: "checkbox",
        })}
      />
      <Tooltip
        key={`${item.key}-tooltip`}
        label="Disconnect House"
        className="bg-error"
      >
        <ActionIcon
          key={`${item.key}-action`}
          color="red"
          variant="hover"
          onClick={() => userForm.removeListItem("houses", index)}
        >
          <FaTimesCircle />
        </ActionIcon>
      </Tooltip>
    </Group>
  ));

  const carsFields = userForm.values.cars.map((item, index) => (
    // <Group key={item.key} mt="xs">
    <div
      className={`flex flex-col gap-y-2 p-2 ${classNames({
        "bg-green-50": !(index % 2 === 0),
      })}`}
      key={`${item.key}-div`}
    >
      {/* <TextInput
        required
        label="House Number"
        {...userForm.getListInputProps("houses", index, "number", {
          type: "input",
        })}
      /> */}
      <div className="flex justify-end">
        <Tooltip label="Disconnect Car" className="bg-error">
          <ActionIcon
            key={"action" + item.key}
            color="red"
            variant="hover"
            onClick={() => userForm.removeListItem("cars", index)}
          >
            <FaTimesCircle />
          </ActionIcon>
        </Tooltip>
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <Tooltip disabled={!item.id} label="Not editable" className="bg-error">
          <TextInput
            label="License Plate"
            required
            readOnly={!!item.id} // disabled if vehicle is in db record
            {...userForm.getListInputProps("cars", index, "plate", {
              type: "input",
            })}
          />
        </Tooltip>
        <Tooltip
          label={
            <div className="flex items-center">
              <p className="text-white">
                Can only be reassigned on Access Keys
              </p>
              <KeyIcon className="h-7 w-7 self-center pb-2 text-white" />
            </div>
          }
        >
          <TextInput label="Access Key" readOnly value={item.rfid} />
        </Tooltip>
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <SelectInput
          required
          key={"brand" + item.key}
          placeholder="Brand"
          nothingFound="No brand found"
          label="Brand"
          options={
            carOptions.isSuccess && carOptions.data
              ? carOptions.data.carBrands.map((brand: any) => {
                  return `${brand.name}`;
                })
              : []
          }
          {...userForm.getListInputProps("cars", index, "brand", {
            type: "input",
          })}
          value={userForm.values.cars[index]?.brand}
        />

        <SelectInput
          required
          key={"model" + item.key}
          placeholder="Model"
          label="Model"
          nothingFound="No models found"
          disabled={userForm.values.cars[index]?.brand === " "}
          /*eslint-disable*/
          options={
            !carOptions.data
              ? []
              : [
                  ...carOptions.data?.carBrands
                    .filter(
                      (carBrand: any) =>
                        carBrand.name === userForm.values.cars[index]?.brand,
                    )
                    .map((carBrand: any) => {
                      return carBrand?.brandModels?.map((model: any) => {
                        return model.name;
                      });
                    })
                    .flat(),
                ]
          }
          /*eslint-enable*/
          {...userForm.getListInputProps("cars", index, "model", {
            type: "input",
          })}
          value={userForm.values.cars[index]?.model}
        />
      </div>
      <div className="flex grow flex-row justify-start gap-3">
        <SelectInput
          required
          placeholder={
            userForm.values.cars[index].year
              ? userForm.values.cars[index].year
              : "Year"
          }
          label="Year"
          nothingFound="Invalid year"
          options={getYears().map((year) => year.toString())}
          {...userForm.getListInputProps("cars", index, "year", {
            type: "input",
          })}
          value={userForm.values.cars[index].year}
        />
        <ColorInput
          required
          label="Color"
          {...userForm.getListInputProps("cars", index, "color", {
            type: "input",
          })}
          value={userForm.values.cars[index].color}
        />
      </div>
      {!item.id && (
        <Typography state="error" className="text-center">
          Make sure License Plate is correct, it can&apos;t be edited once
          submitted.
        </Typography>
      )}
      {/* </Group> */}
    </div>
  ));
  const handleSetItem = (item: any) => {
    userForm.addListItem("cars", {
      id: item.id,
      brand: item.brand,
      model: item.model,
      plate: item.plate,
      year: item.year.toString(),
      color: item.color,
      rfid: item.rfid,
      key: nanoid(),
    });
  };

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={openDrawer}
      onFocus={() => setOpenDrawer(true)}
      // opened={selectedUser !== undefined}
      onClose={() => handleDrawerClose()}
      position="right"
      size="30%"
      withCloseButton
    >
      <Drawer.Header title="Create User" />

      <form
        id="userForm"
        noValidate
        onSubmit={userForm.onSubmit((data) => {
          const body = {
            ...data,
            clusterId: cluster.id,
            cars: data.cars.map((car: any) => ({
              ...car,
              year: parseInt(car.year),
            })),
          };
          return submitPostUser(body);
        })}
      >
        <MAccordion iconPosition="right" initialItem={0}>
          <MAccordion.Item
            label={
              <Typography
                fontState="semibold"
                type="regular"
                state={
                  // REVIEW abstract this repetitive logic
                  Object.keys(userForm.errors).toString().includes("fname") ||
                  Object.keys(userForm.errors).toString().includes("lname") ||
                  Object.keys(userForm.errors).toString().includes("phone") ||
                  Object.keys(userForm.errors).toString().includes("email")
                    ? "error"
                    : undefined
                }
              >
                Info
              </Typography>
            }
          >
            <div className="flex flex-col gap-y-2">
              <TextInput
                form="userForm"
                label="First Name"
                required
                {...userForm.getInputProps("fname")}
              />
              <TextInput
                form="userForm"
                label="Last Name"
                required
                {...userForm.getInputProps("lname")}
              />
              <TextInput
                form="userForm"
                label="Second Last Name"
                {...userForm.getInputProps("lname2")}
              />
              <PhoneInput
                form="userForm"
                label="Phone Number"
                required
                {...userForm.getInputProps("phone")}
              />
              <TextInput
                form="userForm"
                label="Email"
                required
                {...userForm.getInputProps("email")}
              />
              {/* <Switch
                      size="md"
                      className="p-5 flex justify-start"
                      label="Toggle Active status"
                      color="teal"
                      {...userForm.getInputProps("active", { type: "checkbox" })}
                    /> */}
            </div>
          </MAccordion.Item>
          <MAccordion.Item
            label={
              <Typography
                fontState="semibold"
                type="regular"
                state={
                  Object.keys(userForm.errors).toString().includes("houses")
                    ? "error"
                    : undefined
                }
              >
                Houses
              </Typography>
            }
          >
            <div className="mt-5 flex justify-center">
              <Tooltip label="Adds existing house for this record">
                <Button
                  text="Add House"
                  variant="outlined"
                  onClick={() =>
                    userForm.addListItem("houses", {
                      id: "",
                      // number: "",
                      isOwner: false,
                      key: nanoid(),
                    })
                  }
                />
              </Tooltip>
            </div>
            {housesFields}
          </MAccordion.Item>
          {!hideCarsSection && (
            <MAccordion.Item
              // className={hideCarsSection ? "hidden" : undefined}
              label={
                <Typography
                  fontState="semibold"
                  type="regular"
                  state={
                    Object.keys(userForm.errors).toString().includes("cars")
                      ? "error"
                      : undefined
                  }
                >
                  Cars
                </Typography>
              }
            >
              <div className="mt-5 flex justify-center gap-x-3">
                <VehicleSearchPopover
                  popoverOpened={popoverOpened}
                  setPopoverOpened={setPopoverOpened}
                  setItem={handleSetItem}
                />
                <Typography
                  fontState="semibold"
                  type="regular"
                  className="flex flex-col justify-center"
                >
                  or
                </Typography>
                <Tooltip label="For cars that don't exist on SESAM system">
                  <Button
                    text="Create Vehicle"
                    variant="outlined"
                    onClick={() =>
                      userForm.addListItem("cars", {
                        id: "",
                        brand: "",
                        model: "",
                        plate: "",
                        year: "",
                        color: "",
                        rfid: "",
                        key: nanoid(),
                      })
                    }
                  />
                </Tooltip>
              </div>
              {carsFields}
            </MAccordion.Item>
          )}
        </MAccordion>
        {errorsFound && (
          <div className="mt-2 text-center">
            <Typography state="error" className="text-center">
              Errors Found. Make sure required values are provided and correct.
            </Typography>
          </div>
        )}
        <div className="mt-5 flex grow justify-center ">
          <Button
            form="userForm"
            text="Submit"
            type="submit"
            loading={postUserLoading}
            className="grow"
          />
        </div>
      </form>
    </Drawer>
  );
};
