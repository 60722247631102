import axios from "axios";
import { headersAuth } from "src/common/auth";
import {
  AuthVisitorPatch,
  AuthVisitorPost,
  LoginPost,
  UserPatch,
  VisitorsPost,
} from "src/common/schemas";
import {
  AccessKeysRes,
  AuthVisitorsRes,
  Log,
  LogRes,
  UserRes,
  VisitorLogRes,
} from "./types";

export const baseURL = `${process.env.REACT_APP_API_URL}/v1`;
export const axiosClient = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" }, //, "Cache-Control": "no-cache" },
});

export const postLogin = async (body: LoginPost): Promise<any> => {
  const { data } = await axiosClient.post<any>("/login", JSON.stringify(body));
  return data;
};

export const postVisitor = async (body: VisitorsPost): Promise<any> => {
  const { data } = await axiosClient.post<any>(
    "/guard/visitors",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

// TODO add type to body
export const postAccessKey = async (body: any): Promise<any> => {
  console.log("body", body);
  const { data } = await axiosClient.post<any>(
    "/access-keys",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const postAuthVisitor = async (body: AuthVisitorPost): Promise<any> => {
  const { data } = await axiosClient.post<any>(
    "/auth-visitors",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const postHouse = async (body: any): Promise<any> => {
  const { data } = await axiosClient.post<any>(
    "/houses",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const patchAuthVisitor = async (
  body: AuthVisitorPatch,
): Promise<any> => {
  const { data } = await axiosClient.patch<any>(
    `/auth-visitors/${body.id}`,
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const postUser = async (body: any): Promise<any> => {
  const { data } = await axiosClient.post<any>("/users", JSON.stringify(body), {
    headers: headersAuth(),
  });
  return data;
};

export const toggleAccessKey = async (body: {
  id: string;
  active: boolean;
}): Promise<any> => {
  const { data } = await axiosClient.patch<any>(
    `/access-keys/${body.id}`,
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

interface UserPatchId extends UserPatch {
  id: string;
}
export const patchUser = async (body: UserPatchId): Promise<any> => {
  const { data } = await axiosClient.patch<any>(
    `/users/${body.id}`,
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const patchAccessKey = async (body: {
  id: string;
  active?: boolean;
  userId: string;
  vehicleId: string;
}): Promise<any> => {
  const { data } = await axiosClient.patch<any>(
    `/access-keys/${body.id}`,
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const getCarOptions = async (params?: any): Promise<any> => {
  const { data } = await axiosClient.get("/car-options", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getLogs = async (params?: any): Promise<Log[]> => {
  const { data } = await axiosClient.get<Log[]>("/logs", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getAccessKeys = async ({
  clusterId,
  key,
  page,
  perPage,
  type,
  accessPoint,
  user,
}: {
  clusterId: string;
  key?: string;
  token?: string;
  page?: number;
  perPage?: number;
  type?: string;
  user?: string;
  accessPoint?: string;
}): Promise<AccessKeysRes> => {
  const { data } = await axiosClient.get<AccessKeysRes>("/access-keys", {
    params: {
      cid: clusterId,
      key,
      page,
      perPage,
      user,
      type,
      accessPoint,
    },
    headers: headersAuth(),
  });
  return data;
};

export const getAccessLogs = async ({
  clusterId,
  page,
  perPage,
  userId,
  knowns,
  unknowns,
}: {
  clusterId: string;
  page?: number;
  perPage?: number;
  userId?: string;
  knowns?: boolean;
  unknowns?: boolean;
}): Promise<LogRes> => {
  const { data } = await axiosClient.get<LogRes>("/access-logs", {
    params: {
      cid: clusterId,
      page,
      perPage,
      uid: userId,
      knowns,
      unknowns,
    },
    headers: headersAuth(),
  });
  return data;
};

export const getVisitorLogs = async ({
  clusterId,
  page,
  perPage,
}: {
  clusterId: string;
  page?: number;
  perPage?: number;
}): Promise<VisitorLogRes> => {
  const { data } = await axiosClient.get<VisitorLogRes>("/guard/visitors", {
    params: {
      cid: clusterId,
      page,
      perPage,
    },
    headers: headersAuth(),
  });
  return data;
};

export const getUsers = async ({
  clusterId,
  userId,
}: {
  clusterId: string;
  userId?: string | null;
  token?: string | null | undefined;
}): Promise<UserRes> => {
  const { data } = await axiosClient.get<UserRes>("/users", {
    params: { cid: clusterId, uid: userId },
    headers: headersAuth(),
  });
  return data;
};

export const getAuthVisitors = async ({
  clusterId,
}: {
  clusterId: string;
}): Promise<AuthVisitorsRes> => {
  const { data } = await axiosClient.get<AuthVisitorsRes>("/auth-visitors", {
    params: { cid: clusterId },
    headers: headersAuth(),
  });
  return data;
};

export const getUser = async ({
  userId,
}: {
  userId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>(`/users/${userId}`, {
    headers: headersAuth(),
  });

  return data;
};

export const getHouses = async ({
  clusterId,
  token,
}: {
  clusterId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>("/houses", {
    headers: headersAuth(token),
    params: { cid: clusterId },
  });
  return data;
};
