import { Drawer as DrawerMantine } from "@mantine/core";
import { Typography } from "src/components/Typography";
import { DrawerProps } from "./definitions";

export const Drawer = ({
  opened,
  onClose,
  title,
  children,
  position,
  closeOnClickOutside,
  closeOnEscape,
  withCloseButton,
  withOverlay,
  size,
  className,
  onFocus,
}: DrawerProps) => {
  return (
    <DrawerMantine
      onFocus={onFocus}
      opened={opened}
      onClose={onClose}
      title={title}
      position={position}
      closeOnClickOutside={closeOnClickOutside}
      closeOnEscape={closeOnEscape}
      withCloseButton={withCloseButton}
      withOverlay={withOverlay}
      overlayOpacity={0.25}
      size={size}
      className={className}
      classNames={{
        closeButton: "hover:bg-red-300 bg-red-100 text-error transition",
      }}
    >
      {children}
    </DrawerMantine>
  );
};

/** Puts a sticky title on top of the drawer. */
const DrawerHeader = ({ title }: { title: string }) => (
  <Typography
    state="info"
    fontState="semibold"
    type="header"
    className="sticky -top-10 z-10 flex grow justify-center bg-white p-2"
  >
    {title}
  </Typography>
);

Drawer.Header = DrawerHeader;
