import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "react-query";
import {
  getAccessKeys,
  getAccessLogs,
  getAuthVisitors,
  getCarOptions,
  getHouses,
  getUsers,
  getVisitorLogs,
} from "src/common/api";
import {
  AccessKeysRes,
  AuthVisitorsRes,
  LogRes,
  UserRes,
  VisitorLogRes,
} from "src/common/api/types";

export function useHouseQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery(
    ["housesData", clusterId],
    () => getHouses({ clusterId }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return response;
}

export function useAccessPoints() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const locations = user.clusters[0].locations;
  return locations;
}

export function useClusterInfo() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  return user.clusters[0];
}

export function useUsersQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery<UserRes, AxiosError>(
    ["usersData", clusterId],
    () => getUsers({ clusterId }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return response;
}

export function useUserQuery(userId: string) {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery<UserRes, AxiosError>(
    ["userData", clusterId, userId],
    () => getUsers({ clusterId, userId }),
  );
  return response;
}

export function useAuthVisitorsQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery<AuthVisitorsRes, AxiosError>(
    ["authVisitorData", clusterId],
    () => getAuthVisitors({ clusterId }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return response;
}

export function useAccessKeys({
  page,
  perPage,
  type,
  accessPoint,
  user,
  key,
}: {
  page?: number;
  perPage?: number;
  type?: string;
  accessPoint?: string;
  user?: string;
  key?: string;
}) {
  const queryClient = useQueryClient();
  const activeUser: any = queryClient.getQueryData("userAuth");
  const clusterId = activeUser.clusters[0].id;
  const response = useQuery<AccessKeysRes, AxiosError>(
    ["accessKeysData", clusterId, page, perPage, type, accessPoint, user, key],
    () =>
      getAccessKeys({ clusterId, page, perPage, type, accessPoint, user, key }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
  return response;
}

export function useAccessLogs({
  page,
  perPage,
  userId,
  knowns,
  unknowns,
}: {
  page?: number;
  perPage?: number;
  userId?: string;
  knowns?: boolean;
  unknowns?: boolean;
}) {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery<LogRes, AxiosError>(
    ["accessLogs", clusterId, page, perPage, userId, knowns, unknowns],
    () => getAccessLogs({ clusterId, page, perPage, userId, knowns, unknowns }),
    { keepPreviousData: true },
  );
  return response;
}

export function useVisitorLogs({
  page,
  perPage,
}: {
  page?: number;
  perPage?: number;
}) {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  const response = useQuery<VisitorLogRes, AxiosError>(
    ["visitorLogs", clusterId, page, perPage],
    () => getVisitorLogs({ clusterId, page, perPage }),
    { keepPreviousData: true },
  );
  return response;
}

export function useCarOptions() {
  return useQuery<any, AxiosError>(["carOptionsData"], () => getCarOptions());
}
