/** Kept as module.exports for simplicity (no need to be a ts file for config)
 * and compatibility with `tailwind.config.js` */
module.exports = Object.freeze({
  primary: "#0D9E90",
  secondary: "#1EB087",
  error: "#EF2C20",
  "error-alt": "#FF5F5F",
  warning: "#E7B128",
  hover: "#F1F3F5",
});
