import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { postAuthVisitor } from "src/common/api";
import { House } from "src/common/api/types";
import {
  useAuthVisitorsQuery,
  useClusterInfo,
  useHouseQuery,
} from "src/common/hooks";
import { AuthVisitorPost, authVisitorSchema } from "src/common/schemas";
import { httpErrorMessage } from "src/common/utils";
import {
  Button,
  ChipsInputGroup,
  Drawer,
  PhoneInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  Typography,
} from "src/components";

export const CreateAuthVisitorDrawer = ({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
}) => {
  const { data: housesData, refetch: housesRefetch } = useHouseQuery();

  const { refetch: authVisitorRefetch } = useAuthVisitorsQuery();

  const cluster = useClusterInfo();

  const authForm = useForm<AuthVisitorPost>({
    schema: zodResolver(authVisitorSchema),
    initialValues: {
      fname: "",
      lname: "",
      lname2: "",
      phone: "",
      houseId: "",
      userId: "",
      clusterId: cluster.id,
      details: "",
      // FIX enum does not allow empty, but apart from this it does validation well
      // @ts-ignore
      type: "",
    },
  });

  const { mutate: submitPostAuthVisitor, isLoading: postAuthVisitorLoading } =
    useMutation(postAuthVisitor, {
      onSuccess: () => {
        authForm.reset();
        setOpenDrawer(false);
        showNotification({
          title: "Success",
          message: "Authorized visitor created successfully",
          color: "green",
        });
        authVisitorRefetch();
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    });

  const [houseMembers, setHouseMembers] = useState([]);
  useEffect(() => {
    if (authForm.values.houseId) {
      // Filters houses list, and gets a single house object
      const filteredHouse = housesData?.houses
        .filter((house: House) => house.id === authForm.values.houseId)
        .pop();

      // Concatenates the mainContact[] and residents[] for `houseMembers` state
      // used user dropdown
      const hMembers = filteredHouse.residents
        ?.map((resident: any) => {
          return {
            value: resident.id,
            label: `${resident.fname} ${resident.lname}`,
          };
        })
        .concat(
          filteredHouse.mainContact?.map((mainContact: any) => {
            return {
              value: mainContact.id,
              label: `${mainContact.fname} ${mainContact.lname}`,
            };
          }),
        );
      setHouseMembers(hMembers);
    }
  }, [authForm.values.houseId]);

  const handleDrawerClose = () => {
    authForm.reset();
    setHouseMembers([]);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={openDrawer}
      onClose={handleDrawerClose}
      position="right"
      size="30%"
      withCloseButton
    >
      <Drawer.Header title="Add Authorized Visitor" />

      <form
        id="authForm"
        noValidate
        className="flex flex-col gap-y-2"
        onSubmit={authForm.onSubmit((data) => {
          // console.log("SUBMIT", data);
          submitPostAuthVisitor(data);
        })}
      >
        <TextInput
          required
          form="authForm"
          label="First Name"
          {...authForm.getInputProps("fname")}
        />
        <TextInput
          required
          form="authForm"
          label="Last Name"
          {...authForm.getInputProps("lname")}
        />
        <TextInput
          form="authForm"
          label="Second Last Name"
          {...authForm.getInputProps("lname2")}
        />

        <PhoneInput {...authForm.getInputProps("phone")} />

        <SelectInput
          required
          label="Which house the visitor be authorized in?"
          placeholder="Select house number"
          options={
            !housesData
              ? []
              : housesData?.houses?.map((house: House) => {
                  return { value: house.id, label: house.number };
                })
          }
          onDropdownOpen={() => housesRefetch()}
          onSelect={() => authForm.setFieldValue("grantedById", "")}
          {...authForm.getInputProps("houseId")}
        />
        <SelectInput
          required
          label="Which house member is granting access to the visitor?"
          placeholder="Select house member"
          options={houseMembers}
          nothingFound="Must select a House first"
          {...authForm.getInputProps("grantedById")}
        />
        <TextAreaInput
          label="Details"
          placeholder="Additional Details"
          minRows={4}
          {...authForm.getInputProps("details")}
          // value={form.values.details}
        />
        <div className="flex flex-wrap gap-5">
          {/* <Typography type="regular">Select the type of visitor</Typography> */}
          <ChipsInputGroup
            required
            centerChips
            label="Select the type of visitor"
            onChange={(value) => {
              authForm.setFieldValue("type", value);
            }}
            value={authForm.values.type}
            chips={[
              // prettier-ignore
              { value: "VISITOR", children: "Visitor" },
              // prettier-ignore
              { value: "SERVICE", children: "Service"},
              // prettier-ignore
              { value: "CONTRACTOR", children: "Contractor" },
              // prettier-ignore
              { value: "FAMILY", children: "Family" },
            ]}
          />
          {authForm.errors.type && (
            <Typography type="regular" state="error">
              {authForm.errors.type}
            </Typography>
          )}
        </div>
        <div className="mt-5 flex justify-center">
          <Button
            loading={postAuthVisitorLoading}
            text="Submit"
            type="submit"
            form="authForm"
            className="grow"
          />
        </div>
      </form>
    </Drawer>
  );
};
