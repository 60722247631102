import { TimeRangeInput as TimeRangeInputMantine } from "@mantine/dates";
import { TimeRangeInputProps } from "./definitions";

export const TimeRangeInput = ({
  label,
  value,
  onChange,
  format = "12",
  amPmPlaceholder,
  icon,
  radius,
  required,
  size,
  timePlaceholder,
  variant,
}: TimeRangeInputProps) => {
  return (
    <TimeRangeInputMantine
      // FIXME fix styling to be like other inputs
      classNames={{
        input:
          "rounded-lg shadow border-1 border-[#666666] focus:border-2 focus:border-primary",
      }}
      styles={{
        input: { border: "1px solid #666666" },
      }}
      label={label}
      value={value}
      onChange={onChange}
      format={format}
      amPmPlaceholder={amPmPlaceholder}
      icon={icon}
      radius={radius}
      required={required}
      size={size}
      timePlaceholder={timePlaceholder}
      variant={variant}
      clearable
    />
  );
};
