import { BsStickyFill } from "react-icons/bs";
import { FaLaptopHouse, FaQrcode } from "react-icons/fa";
import colors from "src/common/colors";

export const checkInMethod = (
  method: string | null | undefined,
  key: string,
) => {
  if (method === "QRCODE") {
    return (
      <>
        <FaQrcode />
        {/* {key} */}
      </>
    );
  }

  if (method === "RFID") {
    return (
      <>
        <BsStickyFill />
        {key}
      </>
    );
  }

  if (method === "GUARDPOST") {
    return (
      <>
        <FaLaptopHouse />
        {key}
      </>
    );
  }

  return null;
};

export const checkTooltipIcon = (method: string | null | undefined) => {
  if (method === "QRCODE")
    return <FaQrcode color={colors.primary} size={"1.5rem"} />;
  if (method === "RFID")
    return <BsStickyFill color={colors.primary} size={"1.5rem"} />;
  if (method === "GUARDPOST")
    return <FaLaptopHouse color={colors.primary} size={"1.5rem"} />;

  return null;
};
