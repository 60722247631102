import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string()
    .email({ message: "Please provide email registered by the system" }),
  password: z.string().nonempty({ message: "Please provide your password" }),
});

export const visitorSchema = z.object({
  fname: z.string().nonempty({ message: "Required" }),
  lname: z.string().nonempty({ message: "Required" }),
  carBrand: z.string().nonempty({ message: "Required" }), //enum? 😢
  carModel: z.string().nonempty({ message: "Required" }), //enum?
  color: z.string().nonempty({ message: "Required" }),
  carPlate: z.string().nonempty({ message: "Required" }),
  visitorType: z.enum(["VISITOR", "CONTRACTOR", "SERVICE"]),
  // guardId: z.string().nonempty( { message: "Required" }), //logged in guard
  houseId: z.string().cuid({ message: "Must choose a house" }),
  grantedBy: z.string().cuid({ message: "Must be a valid user" }).optional(), //the selected person on house table in access tab it the id of the person, the permit checkmark NOT REQUIRED
  wasGranted: z.boolean(), //either clicked and confirmed deny / access modals. one clicked on either deny or access buttons. deny for false, access for true.
  locationId: z.string().cuid({ message: "Select an access point to open" }), // when clicked on accept or deny, the select input for the gate/site. [label:"location", value:"locationid"]
  timeStart: z.date(), // catch current time vienen del stopwatch component. use zustand store to store/get times
  timeEnd: z.date(), // same as timeStart
  // faceImage: z.string().min(1, { message: "Required" }), // url of s3 bucket with image
  // plateImage: z.string().min(1, { message: "Required" }), // url of s3 bucket with image
  details: z.string().nonempty({ message: "Required" }), // the details on deny access? //NOT REQUIRED, NEED TO BE THERE. the reason for access deny
});

// NOTE this one is its proper form and children of accessKeySchema
export const userPostSchema = z.object({
  fname: z.string().min(1, "Required"),
  lname: z.string().min(1, "Required"),
  lname2: z.string().optional(),
  email: z.string().email().optional(),
  phone: z
    .string()
    .max(14, "Please provide a valid phone number")
    .regex(
      new RegExp(/^\(?\d{3}?\)?[\s]?\d{3}[-\s]?\d{4}[-\s]?$/),
      "Phone number must be in the format of (###) ###-####",
    )
    .optional(), // TODO add validation for phone number
  // houseId: z.string().cuid({ message: "Must choose a house" }),
  houses: z
    .array(
      z.object({
        id: z.string().cuid({ message: "Must choose a house" }),
        // number: z.string().min(1, "Required"),
        isOwner: z.boolean(),
      }),
    )
    .optional(),
  cars: z
    .array(
      z
        .object({
          id: z.string().optional(),
          plate: z.string().min(1, "Provide a plate number"),
          brand: z.string().min(1, "Choose a brand name"),
          model: z.string().min(1, "Choose a model name"),
          year: z.string().min(1, "Provide year"),
          color: z.string().min(1, "Choose a color"),
        })
        .optional(),
    )
    .optional(),
});

// NOTE this one is its proper form and children of accessKeySchema
export const userPatchSchema = z.object({
  fname: z.string().min(1, "Required"),
  lname: z.string().min(1, "Required"),
  lname2: z.string().optional(),
  email: z.string().email().optional(),
  phone: z
    .string()
    .max(14, "Please provide a valid phone number")
    .regex(
      new RegExp(/^\(?\d{3}?\)?[\s]?\d{3}[-\s]?\d{4}[-\s]?$/),
      "Phone number must be in the format of (###) ###-####",
    )
    .optional(), // TODO add validation for phone number
  // houseId: z.string().cuid({ message: "Must choose a house" }),
  houses: z
    .array(
      z.object({
        id: z.string().cuid({ message: "Must choose a house" }),
        // number: z.string().min(1, "Required"),
        isOwner: z.boolean(),
      }),
    )
    .optional(),
  cars: z.array(
    z.object({
      id: z.string().optional(),
      plate: z.string().min(1, "Provide a plate number"),
      brand: z.string().min(1, "Choose a brand name"),
      model: z.string().min(1, "Choose a model name"),
      year: z.string().min(1, "Provide year"), // TODO enforce somehow to be a number
      color: z.string().min(1, "Choose a color"),
    }),
  ),
});

// export const partialUser = userSchema.partial({ email: true });

// NOTE this one is its proper form and children of accessKeySchema
export const vehicleSchema = z.object({
  carBrand: z.string(),
  carModel: z.string(),
  color: z.string(),
  licensePlate: z.string(),
  year: z.string(),
});

enum VisitorType {
  VISITOR = "VISITOR",
  CONTRACTOR = "CONTRACTOR",
  SERVICE = "SERVICE",

  FAMILY = "FAMILY",
}

export const authVisitorSchema = z.object({
  fname: z.string().min(1, "Please provide the first name"),
  lname: z.string().min(1, "Please provide the last name"),
  lname2: z.string().optional(),
  phone: z.string(),
  // .max(14, "Please provide a valid phone number")
  // .regex(
  //   new RegExp(/^\(?\d{3}?\)?[\s]?\d{3}[-\s]?\d{4}[-\s]?$/),
  //   "Phone number must be in the format of (###) ###-####",
  // ), // TODO add validation for phone number

  houseId: z.string().cuid().min(1, "Must choose a house"),
  grantedById: z.string().cuid().min(1, "Must choose a user"),
  details: z.string(),
  // type: z.enum(["VISITOR", "SERVICE", "CONTRACTOR", "FAMILY"], ),
  type: z.nativeEnum(VisitorType, {
    errorMap: () => {
      return { message: "Please select visitor type" };
    },
  }),
  clusterId: z.string(),
});

export const authVisitorUpdateSchema = z.object({
  id: z.string().optional(),
  fname: z.string().min(1, "Please provide the first name"),
  lname: z.string().min(1, "Please provide the last name"),
  lname2: z.string().optional(),
  phone: z.string().optional(),
  // .max(14, "Please provide a valid phone number")
  // .regex(
  //   new RegExp(/^\(?\d{3}?\)?[\s]?\d{3}[-\s]?\d{4}[-\s]?$/),
  //   "Phone number must be in the format of (###) ###-####",
  // ), // TODO add validation for phone number

  details: z.string(),
  // type: z.enum(["VISITOR", "SERVICE", "CONTRACTOR", "FAMILY"], ),
  type: z.nativeEnum(VisitorType, {
    errorMap: () => {
      return { message: "Please select visitor type" };
    },
  }),
  authHouses: z
    .array(
      z.object({
        id: z.string().cuid({ message: "Must choose a house" }),
        key: z.string(),
        // number: z.string().min(1, "Required"),
      }),
    )
    .optional(),
});

export const accessKeySchema = z.object({
  givenById: z.string().cuid({ message: "Must be a valid user id" }).optional(),
  // NOTE either userId or user object, not both
  // userId: z.string().cuid({ message: "Must be a valid user id" }).optional(),
  // user: userSchema.or(z.string().cuid({ message: "Must be a valid user id" })),
  userId: z.string().optional(),
  // NOTE either vehicleId or vehicle object, not both
  // vehicle: vehicleSchema.or(
  //   z.string().cuid({ message: "Must be a valid vehicle id" }),
  // ),
  vehicle: vehicleSchema.optional(),
  // vehicleId: z
  //   .string()
  //   .cuid({ message: "Must be a valid vehicle id" })
  //   .optional(),
  key: z
    .string()
    // .regex(
    //   new RegExp("/^(d{3}))?[-]?(d{5})$/"),
    //   "Must follow the xxx-xxxxx format",
    // )
    // .min(1, "Must provided a key with the xxx-xxxxx format (ej. 019.00001)."),
    .min(1, "Key value is required if RFID.")
    .max(9, "Key value is too long.")
    .regex(
      new RegExp("^[0-9]{3}-[0-9]{5}$"),
      "Key value is not in the correct format (ej. 019-00001).",
    ),
  type: z.enum(["ALWAYSACTIVE", "BYPERIOD", "BYSCHEDULE", "BYUSES"]),
  isOwner: z.boolean().optional(),
  periodFrom: z.date(),
  periodTo: z.date(),
  entryType: z.enum(["RFID", "QRCODE"]),
  // locationIds: z.array(
  //   z.string().cuid({ message: "Must be a valid location id" }),
  // ),
  locationIds: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .min(1, "Must choose Access Points for key to be sent"),
  until: z.number().optional(),
  monday: z.boolean().optional(),
  tuesday: z.boolean().optional(),
  wednesday: z.boolean().optional(),
  thursday: z.boolean().optional(),
  friday: z.boolean().optional(),
  saturday: z.boolean().optional(),
  sunday: z.boolean().optional(),
});

export const editAccessKeySchema = z.object({
  userId: z.string().optional().nullish(),
  vehicleId: z.string().optional().nullish(),
});

export const houseSchema = z.object({
  number: z.string().min(1, "Provide a house number"),
  address: z.string().min(1, "Provide an address"),
  isAdmin: z.boolean(),
  clusterId: z.string(),
});

// Generated types from zod schemas
// #region generated types
export type LoginPost = z.infer<typeof loginSchema>;
export type HouseSchema = z.infer<typeof houseSchema>;

export type UserPatch = z.infer<typeof userPatchSchema>;
export type UserPost = z.infer<typeof userPostSchema>;
export type VisitorsPost = z.infer<typeof visitorSchema>;
export type AccessKeyPost = z.infer<typeof accessKeySchema>;
export type AuthVisitorPost = z.infer<typeof authVisitorSchema>;
export type AuthVisitorPatch = z.infer<typeof authVisitorUpdateSchema>;
// #endregion
