import { Accordion as AccordionMantine } from "@mantine/core";
import { nanoid } from "nanoid";
import { AccordionProps } from "./definitions";

// TODO accordion should be refactored to stop Item .map,
// perhaps a new component (ej. <AccordionDataGrid />)
export const Accordion = ({
  iconPosition = "right",
  iconSize = 18,
  allowMultiple = false,
  items,
  onChange,
}: AccordionProps) => {
  return (
    <AccordionMantine
      classNames={{
        control: "rounded-lg shadow-lg",
        item: "rounded-lg border-x-[0.01em] border-b-[0.01] border-primary mb-3",
        content: "mt-3",
      }}
      iconPosition={iconPosition}
      iconSize={iconSize}
      multiple={allowMultiple}
      onChange={onChange}
    >
      {items.map((item) => (
        <AccordionMantine.Item key={nanoid()} label={item.label}>
          {item.children}
        </AccordionMantine.Item>
      ))}
    </AccordionMantine>
  );
};
