import { FaSignOutAlt } from "react-icons/fa";
import { HeaderProps } from "./definitions";
import { Tooltip } from "./Tooltip";

export const Header = ({
  logo,
  timer,
  emergency,
  showLogout,
  logoutFunc,
}: HeaderProps) => {
  // NOTE padding may be subject to its parent container, in order to have perfect padding on both header content and app content
  return (
    <header
      className="flex h-[10%]
    w-full  items-center justify-between bg-gradient-to-r from-secondary to-primary p-3 md:px-10
    md:py-3" // sticky top-0 z-40"
    >
      {/* // TODO adjust logo sizing */}

      <div className="flex items-center gap-3">
        {logo}
        {showLogout && (
          <Tooltip label="End Shift" position="right">
            <FaSignOutAlt
              className="cursor-pointer"
              color="white"
              onClick={logoutFunc}
            />
          </Tooltip>
        )}
      </div>

      {/* desktop  */}
      {timer && <div className="hidden md:block">{timer}</div>}
      {emergency && <div className="hidden md:block">{emergency}</div>}

      {/* mobile */}
      {timer && emergency && (
        <div className="flex items-center gap-2 md:hidden">
          <span>{timer}</span>
          {emergency}
        </div>
      )}
    </header>
  );
};
