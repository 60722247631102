import { AxiosError } from "axios";
import { HttpCode } from "src/common/enums";

type ErrorMessages = {
  unprocessable_entity?: string;
  bad_request?: string;
  conflict?: string;
};
export const httpErrorMessage = (
  res: AxiosError,
  messages: ErrorMessages = {},
) => {
  // FIXME reenable eslint rule `@typescript-eslint/ban-ts-comment`
  const { bad_request, conflict, unprocessable_entity } = messages;

  switch (res.response?.status) {
    case HttpCode.BAD_REQUEST:
      return (
        // @ts-ignore
        bad_request ?? res.response?.data.message.map((msg) => msg).join(". ")
      );
    case HttpCode.CONFLICT:
      // @ts-ignore
      return conflict ?? res.response?.data?.message;
    case HttpCode.UNPROCESSABLE_ENTITY:
      return unprocessable_entity ?? "Unprocessable entity";
    default:
      return "An error occurred.";
  }
};
