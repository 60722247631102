import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { toggleAccessKey } from "src/common/api";
import { AccessKey } from "src/common/api/types";
import { HttpCode } from "src/common/enums";
import { useAccessKeys } from "src/common/hooks";
import { useAccessKeyStore } from "src/common/store";
import { httpErrorMessage } from "src/common/utils";
import { Button, Modal, Typography } from "src/components";

export const ToggleAccessKeyModal = ({
  isOpen,
  onClose,
  accessKey,
}: {
  isOpen: boolean;
  onClose: () => void;
  accessKey: AccessKey | undefined;
}) => {
  const [queryOptions] = useAccessKeyStore();
  const { refetch: accessKeysRefetch } = useAccessKeys(queryOptions);

  const { mutate: mutateAccessKey, isLoading: loadingAccessKey } = useMutation(
    toggleAccessKey,
    {
      onSuccess: (res) => {
        const evalRes = res.statusCode === HttpCode.ACCEPTED;
        accessKeysRefetch();
        onClose();
        showNotification({
          title: evalRes ? "Accepted" : "Success",
          message: evalRes
            ? "Pending to be sent to control panel"
            : "Access was changed successfully",
          color: evalRes ? "yellow" : "green",
          autoClose: !evalRes,
        });
      },
      onError: (res: AxiosError) => {
        onClose();
        showNotification({
          title: "Error",
          message: httpErrorMessage(res),
          color: "red",
        });
      },
    },
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${accessKey?.active ? "Revoke" : "Grant"} Access`}
      type={accessKey?.active ? "error" : "info"}
      size="lg"
    >
      <Typography
        type="subheader"
        className="my-4 flex justify-center text-center"
      >
        Do you want to {accessKey?.active ? "revoke" : "grant"} the access key{" "}
        {accessKey?.key} from {accessKey?.accessPoint.name}?
      </Typography>

      <div className="flex justify-center">
        <Button
          text="Cancel"
          color="error"
          variant="outlined"
          className="mr-2"
          size="xs"
          onClick={onClose}
        />
        <Button
          text="Confirm"
          size="xs"
          loading={loadingAccessKey}
          onClick={() =>
            mutateAccessKey({
              active: !accessKey?.active,
              id: accessKey?.id || "",
            })
          }
        />
      </div>
    </Modal>
  );
};
