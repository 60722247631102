import React, { useEffect, useState } from "react";
import { NavBarProps } from "./definitions";
// import classNames from "classnames";

/** Primary UI component for user interaction */
export const Navbar = ({
  alignment,
  position,
  children,
  className,
}: NavBarProps) => {
  const [alignTo, setAlignTo] = useState("flex-row");
  const [positionTo, setPositionTo] = useState("top-0 left-0");

  useEffect(() => {
    if (alignment === "horizontal") setAlignTo("flex-row");
    if (alignment === "vertical") setAlignTo("flex-col");

    if (position === "top-left") setPositionTo("top-0 left-0");
    if (position === "top-right") setPositionTo("top-0 right-0");
    if (position === "bottom-left") setPositionTo("bottom-0 left-0");
    if (position === "bottom-right") setPositionTo("bottom-0 right-0");
  }, []);

  return (
    <nav
      className={`flex ${alignTo} absolute gap-3 ${positionTo} ${className}`}
    >
      {children}
    </nav>
  );
};
