import { Accordion as MAccordion, Autocomplete } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { patchAccessKey } from "src/common/api";
import { useVehiclesFetch } from "src/common/api/hooks";
import { useAccessKeys, useUsersQuery } from "src/common/hooks";
import { editAccessKeySchema } from "src/common/schemas";
import { useAccessKeyStore } from "src/common/store";
import { httpErrorMessage } from "src/common/utils";
import {
  Button,
  Drawer,
  Loader,
  SelectInput,
  TextInput,
  Tooltip,
  Typography,
} from "src/components";

export const EditAccessKeyDrawer = ({
  setOpenEditDrawer,
  selectedKey,
}: {
  openEditDrawer?: boolean;
  setOpenEditDrawer: (openEditDrawer: boolean) => void;
  selectedKey:
    | // UGLY do proper type
    {
        id: string;
        key: string;
        user: { id: string };
        vehicle: { id: string; plate: string };
        location: { id: string; name: string };
      }
    | undefined
    | null;
}) => {
  const handleDrawerClose = () => {
    const param = searchParams.get("id");

    if (param) {
      // delete each query param
      searchParams.delete("id");

      // update state after
      setSearchParams(searchParams);
    }

    setOpenEditDrawer(false);
    accessEditForm.reset();
  };

  const [queryOptions] = useAccessKeyStore();
  const {
    data: usersData,
    isSuccess: usersSuccess,
    refetch: usersRefetch,
  } = useUsersQuery();
  const { refetch: keysRefetch } = useAccessKeys(queryOptions);

  const [searchParams, setSearchParams] = useSearchParams();

  const [, setPopoverOpened] = useState(false);

  const accessEditForm = useForm({
    schema: zodResolver(editAccessKeySchema),
    initialValues: {
      userId: selectedKey?.user?.id ?? ("" as string | null | undefined),
      vehicleId: selectedKey?.vehicle?.id ?? ("" as string | null | undefined),
      vehiclePlate:
        selectedKey?.vehicle?.plate ?? ("" as string | null | undefined),
    },
  });

  const [plate, setPlate] = useState("");
  const [debouncedPlate] = useDebouncedValue(plate, 250);
  const [{ data: vehicleData, loading: vehicleLoading }] = useVehiclesFetch({
    plate: debouncedPlate,
    uid: accessEditForm.values.userId,
  });

  const vehicleOptions =
    !vehicleLoading && vehicleData
      ? vehicleData.data.map((vehicle: any) => ({
          value: vehicle.plate,
          id: vehicle.id,
          plate: vehicle.plate,
          color: vehicle.color,
          model: vehicle.model,
          brand: vehicle.brand,
          year: vehicle.year,
          rfid: vehicle.rfid,
        }))
      : [];

  const { mutate: submitPatchAK, isLoading: patchAkLoading } = useMutation(
    patchAccessKey,
    {
      onSuccess: () => {
        accessEditForm.reset();
        usersRefetch();
        keysRefetch();
        handleDrawerClose();
        showNotification({
          title: "Success",
          message: "Access Key was edited successfully",
          color: "green",
        });
      },
      onError: (res: AxiosError) => {
        showNotification({
          title: "Error",
          message: httpErrorMessage(res, {
            unprocessable_entity: "Access Key already exists.",
          }),
          color: "red",
        });
      },
    },
  );

  return (
    <Drawer
      className="overflow-y-auto p-10"
      opened={selectedKey !== null && searchParams.get("id") !== null}
      onFocus={() => setOpenEditDrawer(true)}
      onClose={() => handleDrawerClose()}
      position="right"
      size="30%"
      withCloseButton
    >
      <Drawer.Header title="Edit Access Key" />

      {!selectedKey ? (
        // REVIEW don't think this is needed
        <div className="mt-5 flex items-center justify-center">
          <Loader size={"lg"} />
        </div>
      ) : (
        <form
          id="accessEditForm"
          noValidate
          onSubmit={accessEditForm.onSubmit((data) => {
            const body = {
              ...data,
              id: selectedKey.id,
              userId: data.userId === "" ? undefined : data.userId,
            };
            // @ts-ignore
            return submitPatchAK(body);
          })}
        >
          <MAccordion iconPosition="right" initialItem={0}>
            <MAccordion.Item
              label={
                <Typography fontState="semibold" type="regular">
                  Info
                </Typography>
              }
            >
              <div className="flex flex-col gap-y-2">
                <Tooltip
                  label="Not editable, for reference only"
                  placement="start"
                  className="bg-error"
                >
                  <TextInput disabled value={selectedKey.key} label="RFID" />
                </Tooltip>
                <Tooltip
                  label="Not editable, for reference only"
                  placement="start"
                  className="bg-error"
                >
                  <TextInput
                    disabled
                    value={selectedKey.location.name}
                    label="Access Point"
                  />
                </Tooltip>
                <SelectInput
                  label="User"
                  placeholder="Select User"
                  options={
                    usersSuccess && usersData
                      ? usersData.users.map((user: any) => {
                          return {
                            label: `${user.fname} ${user.lname} ${user.lname2}`,
                            value: user.id,
                          };
                        })
                      : [{ label: "error", value: "error" }]
                  }
                  value={accessEditForm.values.userId ?? ""}
                  onSelect={() => {
                    const { setFieldValue } = accessEditForm;
                    setFieldValue("vehicleId", "");
                    setFieldValue("vehiclePlate", "");
                  }}
                  onChange={(value) => {
                    accessEditForm.setFieldValue("userId", value);
                  }}
                />
                <Button
                  text="Disconnect User"
                  onClick={() => accessEditForm.setFieldValue("userId", null)}
                />
                <Autocomplete
                  label="Vehicle"
                  placeholder="Search by License Plate"
                  limit={4}
                  data={vehicleOptions}
                  nothingFound={
                    !vehicleLoading && vehicleData?.total === 0 ? (
                      "No cars under selected user. Edit User to add cars."
                    ) : (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    )
                  }
                  value={accessEditForm.values.vehiclePlate ?? ""}
                  onChange={(value) => {
                    accessEditForm.setFieldValue("vehiclePlate", value);
                    setPlate(value);
                  }}
                  onItemSubmit={(item) => {
                    accessEditForm.setFieldValue("vehicleId", item.id);
                    accessEditForm.setFieldValue("vehiclePlate", item.plate);
                    setPlate("");
                    setPopoverOpened(false);
                  }}
                />
                <Button
                  text="Disconnect Car"
                  onClick={() => {
                    accessEditForm.setFieldValue("vehicleId", null);
                    accessEditForm.setFieldValue("vehiclePlate", null);
                  }}
                />
              </div>
            </MAccordion.Item>
          </MAccordion>
          <div className="mt-5 flex grow justify-center ">
            <Button
              form="accessEditForm"
              text="Submit"
              type="submit"
              loading={patchAkLoading}
              className="grow"
            />
          </div>
          {process.env.NODE_ENV === "development" && (
            <details className="olan">
              <pre>{JSON.stringify(selectedKey, null, 2)}</pre>
            </details>
          )}
        </form>
      )}
    </Drawer>
  );
};
