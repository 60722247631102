import classNames from "classnames";
import { useState } from "react";
import { DayPickerProps } from "./definitions";

export const DayPicker = ({
  onChange,
  checked,
  name,
  value,
  defaultValue,
  label,
  htmlFor,
}: DayPickerProps) => {
  const [checkedState, setCheckedState] = useState(checked);

  return (
    <div
      className={`relative flex aspect-square w-3/12 flex-col items-center justify-center rounded-3xl p-3 shadow-lg ${classNames(
        { "bg-primary text-white": checkedState },
        { "bg-white text-[#837A7A]": !checkedState },
      )}`}
    >
      <input
        className="absolute z-40 h-full w-full cursor-pointer opacity-0"
        type="checkbox"
        onChange={onChange}
        onClick={() => setCheckedState(!checkedState)}
        checked={checked}
        name={name}
        value={value}
        defaultValue={defaultValue}
      />
      <span className="flex flex-col items-center justify-center gap-3">
        <label htmlFor={htmlFor}>{label}</label>
      </span>
    </div>
  );
};
