import { Autocomplete, Popover } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";
import { useVehiclesFetch } from "src/common/api/hooks";
import { Button, Loader, Tooltip } from "src/components";

export const VehicleSearchPopover = ({
  popoverOpened,
  setPopoverOpened,
  setItem,
}: {
  popoverOpened: boolean;
  setPopoverOpened: (value: boolean) => void;
  setItem: any;
}) => {
  const [plate, setPlate] = useState("");
  const [debouncedPlate] = useDebouncedValue(plate, 250);
  const [{ data: vehicleData, loading: vehicleLoading }] = useVehiclesFetch({
    plate: debouncedPlate,
  });
  const vehicleOptions =
    !vehicleLoading && vehicleData
      ? vehicleData.data.map((vehicle: any) => ({
          value: vehicle.plate,
          id: vehicle.id,
          plate: vehicle.plate,
          color: vehicle.color,
          model: vehicle.model,
          brand: vehicle.brand,
          year: vehicle.year,
          rfid: vehicle.rfid,
        }))
      : [];

  return (
    <Tooltip label="Searches existing car by license plate">
      <Popover
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
        withArrow
        position="left"
        classNames={{ popover: "w-full h-[50%]" }}
        target={
          <Button
            text="Add Vehicle"
            variant="outlined"
            onClick={() => setPopoverOpened(true)}
          />
        }
      >
        <Autocomplete
          label="License Plate"
          limit={4}
          data={vehicleOptions}
          nothingFound={
            !vehicleLoading && vehicleData?.total === 0 ? (
              "No results"
            ) : (
              <div className="flex justify-center">
                <Loader />
              </div>
            )
          }
          value={plate}
          onChange={(value) => setPlate(value)}
          onItemSubmit={(item) => {
            setItem(item);
            setPlate("");
            setPopoverOpened(false);
          }}
        />
      </Popover>
    </Tooltip>
  );
};
